import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FaPlus, FaSave } from "react-icons/fa";
import { ImCross } from "react-icons/im";
axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

function EditProductForm({ isOpen, onClose, onSave, initialData }) {
  const [formData, setFormData] = useState({
    main_category_id: "",
    category_id: "",
    warehouse_id: "",
    purchase_price: "",
    stock_quantity: "",
    lot_number: "",
    arrival_date: "",
    expiration_date: "",
    unit: "",
    vat_id: "",
    supplier_id: "",
    bill_number: "",
  });

  const [mainCategories, setMainCategories] = useState([]);
  const [childCategories, setChildCategories] = useState({});
  const [warehouses, setWarehouses] = useState([]);
  const [units, setUnits] = useState([]);
  const [vatRates, setVatRates] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      fetchSuppliers();
      fetchCategories();
      fetchActiveWarehouses();
      fetchUnits();
      fetchVatRates();
    }
  }, [isOpen]);

  useEffect(() => {
    if (initialData) {
      setFormData({
        ...initialData,
        arrival_date: initialData.arrival_date
          ? initialData.arrival_date.split("T")[0]
          : "",
        expiration_date: initialData.expiration_date
          ? initialData.expiration_date.split("T")[0]
          : "",
        bill_number: initialData.bill_number,
      });
    }
  }, [initialData]);

  const fetchSuppliers = async () => {
    try {
      const response = await axios.get("/suppliers");
      setSuppliers(response.data);
    } catch (error) {
      console.error("Failed to fetch suppliers:", error);
      setSuppliers([]); // Ensure suppliers is an array even if the fetch fails
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get("/setup/categories");
      const categories = response.data;
      const mainCategories = categories.filter((cat) => !cat.parent_id);
      const childCategoriesMap = {};

      categories.forEach((cat) => {
        if (cat.parent_id) {
          if (!childCategoriesMap[cat.parent_id]) {
            childCategoriesMap[cat.parent_id] = [];
          }
          childCategoriesMap[cat.parent_id].push(cat);
        }
      });

      setMainCategories(mainCategories);
      setChildCategories(childCategoriesMap);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  const fetchActiveWarehouses = async () => {
    try {
      const response = await axios.get("/setup/warehouses/active");
      setWarehouses(response.data);
    } catch (error) {
      console.error("Failed to fetch warehouses:", error);
    }
  };

  const fetchUnits = async () => {
    try {
      const response = await axios.get("/setup/units");
      setUnits(response.data);
    } catch (error) {
      console.error("Failed to fetch units:", error);
    }
  };

  const fetchVatRates = async () => {
    try {
      const response = await axios.get("/setup/vat-rates");
      setVatRates(response.data);
    } catch (error) {
      console.error("Failed to fetch VAT rates:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "main_category_id") {
      setFormData((prev) => ({ ...prev, category_id: "" })); // Reset child category selection
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSave(formData);
    onClose();
  };

  return (
    <Modal show={isOpen} onHide={onClose} size="xl" backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("editProductModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} id="form-100">
          <Row>
            <Col md={6}>
              <Form.Group controlId="supplier_id">
                <Form.Label>{t("editProductModal.supplierId")}</Form.Label>
                <Form.Control
                  as="select"
                  name="supplier_id"
                  value={formData.supplier_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">
                    {t("editProductModal.selectSupplier")}
                  </option>
                  {Array.isArray(suppliers) &&
                    suppliers.map((supplier) => (
                      <option
                        key={supplier.supplier_id}
                        value={supplier.supplier_id}
                      >
                        {supplier.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="bill_number">
                <Form.Label>{t("editProductModal.billNumber")}</Form.Label>
                <Form.Control
                  type="text"
                  name="bill_number"
                  value={formData.bill_number}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="main_category_id">
                <Form.Label>{t("editProductModal.mainCategory")}</Form.Label>
                <Form.Control
                  as="select"
                  name="main_category_id"
                  value={formData.main_category_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">
                    {t("editProductModal.selectMainCategory")}
                  </option>
                  {mainCategories.map((mainCat) => (
                    <option
                      key={mainCat.category_id}
                      value={mainCat.category_id}
                    >
                      {mainCat.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="category_id">
                <Form.Label>{t("editProductModal.childCategory")}</Form.Label>
                <Form.Control
                  as="select"
                  name="category_id"
                  value={formData.category_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">
                    {t("editProductModal.selectChildCategory")}
                  </option>
                  {childCategories[formData.main_category_id] &&
                    childCategories[formData.main_category_id].map(
                      (childCat) => (
                        <option
                          key={childCat.category_id}
                          value={childCat.category_id}
                        >
                          {childCat.name}
                        </option>
                      )
                    )}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="warehouse_id">
                <Form.Label>{t("editProductModal.warehouse")}</Form.Label>
                <Form.Control
                  as="select"
                  name="warehouse_id"
                  value={formData.warehouse_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">
                    {t("editProductModal.selectWarehouse")}
                  </option>
                  {warehouses.map((warehouse) => (
                    <option
                      key={warehouse.warehouse_id}
                      value={warehouse.warehouse_id}
                    >
                      {warehouse.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="unit">
                <Form.Label>{t("editProductModal.unit")}</Form.Label>
                <Form.Control
                  as="select"
                  name="unit"
                  value={formData.unit}
                  onChange={handleChange}
                  required
                >
                  <option value="">{t("editProductModal.selectUnit")}</option>
                  {units.map((unit) => (
                    <option key={unit.unit_id} value={unit.name}>
                      {unit.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="purchase_price">
                <Form.Label>{t("editProductModal.purchasePrice")}</Form.Label>
                <Form.Control
                  type="number"
                  name="purchase_price"
                  value={formData.purchase_price}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="stock_quantity">
                <Form.Label>{t("editProductModal.stockQuantity")}</Form.Label>
                <Form.Control
                  type="number"
                  name="stock_quantity"
                  value={formData.stock_quantity}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="lot_number">
                <Form.Label>{t("editProductModal.lotNumber")}</Form.Label>
                <Form.Control
                  type="text"
                  name="lot_number"
                  value={formData.lot_number}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="arrival_date">
                <Form.Label>{t("editProductModal.arrivalDate")}</Form.Label>
                <Form.Control
                  type="date"
                  name="arrival_date"
                  value={formData.arrival_date}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="expiration_date">
                <Form.Label>{t("editProductModal.expirationDate")}</Form.Label>
                <Form.Control
                  type="date"
                  name="expiration_date"
                  value={formData.expiration_date}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="vat_id">
                <Form.Label>{t("editProductModal.vatRate")}</Form.Label>
                <Form.Control
                  as="select"
                  name="vat_id"
                  value={formData.vat_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">
                    {t("editProductModal.selectVatRate")}
                  </option>
                  {vatRates.map((rate) => (
                    <option key={rate.vat_id} value={rate.vat_id}>
                      {rate.name} ({rate.rate}%)
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <div className="col-md-12 d-flex justify-content-end">
            <button type="submit" className="btn btn-success w-100 me-2">
              <FaSave />
              <span className="text-icon ms-2">
                {t("editProductModal.saveButton")}
              </span>
            </button>
            <button
              type="button"
              className="btn btn-danger w-100"
              onClick={onClose}
            >
              <ImCross />
              <span className="text-icon ms-2">
                {t("editProductModal.cancelButton")}
              </span>
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditProductForm;
