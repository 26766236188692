import React, { useState, useEffect } from "react";
import axios from "axios";
import PaymentMethodForm from "./PaymentMethodForm";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash } from "react-icons/fa";

function PaymentMethods() {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState({
    method_name: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/setup/payment-methods`
      );
      setPaymentMethods(response.data);
    } catch (error) {
      console.error(t("paymentMethods.fetchError"), error);
    }
  };

  const handleAddOrUpdatePaymentMethod = async (paymentMethodData) => {
    const method = paymentMethodData.payment_method_id ? "put" : "post";
    const url = paymentMethodData.payment_method_id
      ? `${process.env.REACT_APP_API_URL}/setup/payment-methods/${paymentMethodData.payment_method_id}`
      : `${process.env.REACT_APP_API_URL}/setup/payment-methods`;
    try {
      const response = await axios[method](url, paymentMethodData);
      console.log(
        paymentMethodData.payment_method_id
          ? "Payment method updated"
          : "Payment method added",
        response.data
      );
      fetchPaymentMethods();
    } catch (error) {
      console.error(
        "Error updating/adding payment method:",
        error.response ? error.response.data : error
      );
      alert(
        `Error updating payment method: ${
          error.response
            ? JSON.stringify(error.response.data)
            : "Server not responding"
        }`
      );
    }
    closeModal();
  };

  const handleDeletePaymentMethod = async (paymentMethodId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/setup/payment-methods/${paymentMethodId}`
      );
      fetchPaymentMethods();
    } catch (error) {
      console.error(t("paymentMethods.deleteError"), error);
    }
  };

  const openModalToAdd = () => {
    setCurrentPaymentMethod({ method_name: "" });
    setModalIsOpen(true);
  };

  const openModalToEdit = (paymentMethod) => {
    setCurrentPaymentMethod(paymentMethod);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentPaymentMethod({ method_name: "" });
  };

  return (
    <div className="container-fluid ">
      <h2>{t("paymentMethods.title")}</h2>
      <button
        className="btn btn-primary my-3 w-29-5 text-center"
        onClick={openModalToAdd}
      >
        {t("paymentMethods.addNew")}
      </button>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("paymentMethods.methodName")}</th>
              <th>{t("paymentMethods.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {paymentMethods.map((paymentMethod) => (
              <tr key={paymentMethod.payment_method_id}>
                <td>{paymentMethod.method_name}</td>
                <td className="d-flex ">
                  <button
                    className="btn btn-success m-1"
                    onClick={() => openModalToEdit(paymentMethod)}
                  >
                    <FaEdit />

                    <span className="text-icon">
                      {t("paymentMethods.edit")}
                    </span>
                  </button>
                  <button
                    className="btn btn-danger m-1"
                    onClick={() =>
                      handleDeletePaymentMethod(paymentMethod.payment_method_id)
                    }
                  >
                    <FaTrash />
                    <span className="text-icon">
                      {t("paymentMethods.delete")}
                    </span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalIsOpen && (
        <PaymentMethodForm
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSave={handleAddOrUpdatePaymentMethod}
          initialData={currentPaymentMethod}
        />
      )}
    </div>
  );
}

export default PaymentMethods;
