import React, { useState, useEffect } from "react";
import axios from "axios";
import EditOrderModal from "./EditOrderModal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import OrderItemViewModal from "./OrderItemViewModal";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [vatRates, setVatRates] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [error, setError] = useState("");
  const [showOrderItemModal, setShowOrderItemModal] = useState(false);
  const [selectedOrderItem, setSelectedOrderItem] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    fetchData("orders", setOrders);
    fetchData("customers", setCustomers);
    fetchData("inventory/select", setProducts); // Corrected endpoint
    fetchData("setup/payment-methods", setPaymentMethods);
    fetchData("setup/vat-rates", setVatRates);
  }, []);

  const fetchData = async (endpoint, setState) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${endpoint}`
      );
      setState(response.data);
      setError("");
    } catch (error) {
      console.error(`Failed to fetch ${endpoint}:`, error);
      setError(t("errors.fetchFailed"));
    }
  };

  const handleAddOrUpdateOrder = async (orderData) => {
    const apiEndpoint = currentOrder
      ? `${process.env.REACT_APP_API_URL}/orders/${currentOrder.order_id}`
      : `${process.env.REACT_APP_API_URL}/orders`;
    const method = currentOrder ? "put" : "post";

    try {
      await axios[method](apiEndpoint, orderData);
      fetchData("orders", setOrders);
      closeModal();
    } catch (error) {
      console.error(
        "Failed to add or update order:",
        error.response?.data || error.message
      );
      setError(
        t("errors.updateFailed") +
          ": " +
          (error.response?.data?.error || error.message)
      );
    }
  };

  const handleDeleteOrder = async (orderId) => {
    if (!window.confirm(t("confirmDelete"))) return;

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/orders/${orderId}`);
      fetchData("orders", setOrders);
    } catch (error) {
      console.error(
        "Failed to delete order:",
        error.response?.data || error.message
      );
      setError(
        t("errors.deleteFailed") +
          ": " +
          (error.response?.data?.error || error.message)
      );
    }
  };

  const openModalToAdd = () => {
    setCurrentOrder(null);
    setModalIsOpen(true);
  };

  const openModalToEdit = (order) => {
    setCurrentOrder(order);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentOrder(null);
  };

  const calculateTotalPrice = (items, vatRate) => {
    const total = items.reduce(
      (total, item) =>
        total + parseFloat(item.sell_price) * parseInt(item.quantity),
      0
    );
    return (total + (total * vatRate) / 100).toFixed(2);
  };

  return (
    <div className="container-fluid mt-4">
      <h2>{t("orders.title")}</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <button
        className="btn btn-primary my-3 w-29-5 text-center"
        onClick={openModalToAdd}
      >
        {t("orders.addNew")}
      </button>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("orders.customer")}</th>
              <th>{t("orders.billNumber")}</th>
              <th>{t("orders.date")}</th>
              <th>{t("orders.status")}</th>
              <th>{t("orders.paymentStatus")}</th>
              <th>{t("orders.productDetails")}</th>
              <th>{t("orders.totalPrice")}</th>
              <th>{t("orders.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.order_id}>
                <td>{order.customer_name}</td>
                <td>{order.bill_number}</td>
                <td>{new Date(order.order_date).toLocaleDateString()}</td>
                <td className={order.status == "pending" ? "bg-warning" : ""}>
                  {order.status}
                </td>
                <td>{order.payment_status}</td>
                <td>
                  <button
                    className="btn btn-light"
                    onClick={() => {
                      console.log("clicked ---------");
                      setSelectedOrderItem(order.items);
                      setShowOrderItemModal(true);
                    }}
                  >
                    {t("orders.productDetails")}
                  </button>
                </td>
                <td>{order.totalPrice}</td>
                <td className="d-flex align-items-center ">
                  <div className="row w-100">
                    {order.status === "pending" && (
                      <>
                        <div className="col-3">
                          <button
                            className="btn btn-success m-2 d-flex align-items-center w-100 justify-content-center"
                            onClick={() => openModalToEdit(order)}
                          >
                            <FaEdit />
                            <span className="text-icon">
                              {" "}
                              {t("orders.edit")}
                            </span>
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            className="btn btn-danger m-2 d-flex align-items-center w-100 justify-content-center"
                            onClick={() => handleDeleteOrder(order.order_id)}
                          >
                            <FaTrash />
                            <span className="text-icon">
                              {" "}
                              {t("orders.delete")}
                            </span>
                          </button>
                        </div>
                      </>
                    )}
                    <div
                      className={`col-${order.status === "pending" ? 6 : 12}`}
                    >
                      <Link
                        to={`/delivery-notes`}
                        state={{ order }}
                        className="btn btn-primary m-3 w-100 d-flex align-items-center justify-content-center"
                      >
                        <FaEye className="m-2" />
                        {t("orders.viewDeliveryNotes")}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalIsOpen && (
        <EditOrderModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSave={handleAddOrUpdateOrder}
          initialData={currentOrder || {}}
          customers={customers}
          products={products}
          paymentMethods={paymentMethods}
          vatRates={vatRates}
        />
      )}
      {showOrderItemModal && (
        <OrderItemViewModal
          items={selectedOrderItem}
          handleClose={() => setShowOrderItemModal(false)}
          show={showOrderItemModal}
        />
      )}
    </div>
  );
};

export default Orders;
