import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function ExpensesSummary() {
    const { t } = useTranslation();
    const [groupedExpenses, setGroupedExpenses] = useState([]);
    const [selectedMonthYear, setSelectedMonthYear] = useState('');
    const [expenses, setExpenses] = useState([]);

    useEffect(() => {
        fetchGroupedExpenses();
    }, []);

    useEffect(() => {
        if (selectedMonthYear) {
            const [year, month] = selectedMonthYear.split('-');
            fetchExpenses(year, month);
        }
    }, [selectedMonthYear]);

    const fetchGroupedExpenses = async () => {
        try {
            const response = await axios.get('/expenses/grouped');
            setGroupedExpenses(response.data);
        } catch (error) {
            console.error(t('errors.fetchFailed'), error);
        }
    };

    const fetchExpenses = async (year, month) => {
        try {
            const response = await axios.get(`/expenses/${year}/${month}`);
            setExpenses(response.data);
        } catch (error) {
            console.error(t('errors.fetchFailed'), error);
        }
    };

    const generatePDF = () => {
        const doc = new jsPDF();
        const [year, month] = selectedMonthYear.split('-');
        doc.text(`${t('expenses.title')} - ${year}-${month}`, 14, 16);
        doc.autoTable({
            head: [[t('expenses.date'), t('expenses.description'), t('expenses.amount')]],
            body: expenses.map(expense => [new Date(expense.date).toLocaleDateString(), expense.description, `${expense.amount} Ft`]),
            startY: 20,
        });
        doc.save(`expenses_summary_${year}_${month}.pdf`);
    };

    return (
        <div className="container mt-4">
            <h2>{t('expenses.title')}</h2>
            <div className="form-group">
                <label>{t('expenses.selectMonthYear')}</label>
                <select
                    className="form-control"
                    value={selectedMonthYear}
                    onChange={(e) => setSelectedMonthYear(e.target.value)}
                >
                    <option value="">{t('expenses.selectMonthYear')}</option>
                    {groupedExpenses.map((group, index) => (
                        <option key={index} value={group.month_year}>{group.month_year}</option>
                    ))}
                </select>
            </div>
            <button className="btn btn-primary" onClick={generatePDF} disabled={!selectedMonthYear}>
                {t('expenses.downloadPdf')}
            </button>
            {expenses.length > 0 && (
                <table className="table table-striped mt-4">
                    <thead className="thead-dark">
                        <tr>
                            <th>{t('expenses.date')}</th>
                            <th>{t('expenses.description')}</th>
                            <th>{t('expenses.amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {expenses.map((expense, index) => (
                            <tr key={index}>
                                <td>{new Date(expense.date).toLocaleDateString()}</td>
                                <td>{expense.description}</td>
                                <td>{`${expense.amount} Ft`}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default ExpensesSummary;
