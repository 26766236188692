import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CutsModal from "./CutsModal";
import { ImCross } from "react-icons/im";
function LabManagement({
  recipes,
  labProcesses,
  fetchInventory,
  inventory,
  products,
  fetchLabProcesses,
  fetchProducts,
  setErrorMessage,
}) {
  const { t } = useTranslation();
  const [showCuts, setShowCuts] = useState(false);
  const [selectedProcessStatus, setSelectedProcessStatus] = useState("");
  const [cuts, setCuts] = useState([]);
  const [newProcess, setNewProcess] = useState({
    recipe_id: "",
    input_product_id: "",
    input_quantity: "",
    execution_date: "",
    status: "Pending",
  });
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  const addLabProcess = async () => {
    try {
      await axios.post("/lab", newProcess);
      fetchLabProcesses();
      fetchProducts(); // Refresh products to update inventory after adding lab process
      setNewProcess({
        recipe_id: "",
        input_product_id: "",
        input_quantity: "",
        execution_date: "",
        status: "Pending",
      });
    } catch (error) {
      console.error(t("errors.addFailed"), error);
      setErrorMessage(t("errors.addFailed"));
    }
  };

  const handleRecipeChange = (recipe_id) => {
    const recipe = recipes.find((r) => r.recipe_id === recipe_id);
    setSelectedRecipe(recipe);
    setNewProcess({ ...newProcess, recipe_id });
  };

  const addToInventory = async (processId) => {
    try {
      await axios.put(`/lab/add-to-inventory/${processId}`);
      fetchLabProcesses();
      fetchProducts(); // Update the inventory after adding the outputs
    } catch (error) {
      console.error(t("errors.updateFailed"), error);
      setErrorMessage(t("errors.updateFailed"));
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="card mb-4">
      <div className="card-header">
        <h2>{t("labProcesses.title")}</h2>
      </div>
      <div className="card-body">
        <div className="mb-3">
          <select
            className="form-control mb-2"
            value={newProcess.recipe_id}
            onChange={(e) => handleRecipeChange(e.target.value)}
          >
            <option value="">{t("labProcesses.selectRecipe")}</option>
            {recipes.map((recipe) => (
              <option key={recipe.recipe_id} value={recipe.recipe_id}>
                {recipe.name}
              </option>
            ))}
          </select>
          <select
            className="form-control mb-2"
            value={newProcess.input_product_id}
            onChange={(e) =>
              setNewProcess({ ...newProcess, input_product_id: e.target.value })
            }
          >
            <option value="">{t("labProcesses.selectProduct")}</option>
            {inventory.map((item) => (
              <option key={item.product_id} value={item.product_id}>
                {item.main_category_name} - {item.child_category_name} -{" "}
                {item.stock_quantity}
              </option>
            ))}
          </select>
          <input
            type="number"
            className="form-control mb-2"
            placeholder={t("labProcesses.inputQuantity")}
            value={newProcess.input_quantity}
            onChange={(e) =>
              setNewProcess({ ...newProcess, input_quantity: e.target.value })
            }
          />
          <input
            type="date"
            className="form-control mb-2"
            value={newProcess.execution_date}
            onChange={(e) =>
              setNewProcess({ ...newProcess, execution_date: e.target.value })
            }
          />
          <button className="btn btn-primary" onClick={addLabProcess}>
            {t("labProcesses.add")}
          </button>
        </div>
        {selectedRecipe && (
          <div className="mb-4">
            <h3>{t("labProcesses.recipeDetails")}</h3>
            <ul className="list-group">
              {selectedRecipe.main_pieces.map((mainPiece, mainPieceIndex) => (
                <li key={mainPieceIndex} className="list-group-item">
                  {mainPiece.name}
                  <ul className="list-group">
                    {mainPiece.cuts.map((cut, cutIndex) => (
                      <li key={cutIndex} className="list-group-item">
                        {cut.name}: {cut.description} ({cut.percentage}%)
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        )}
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              <th>{t("labProcesses.recipeName")}</th>
              <th>{t("labProcesses.inputProduct")}</th>
              <th>{t("labProcesses.inputLotNumber")}</th>
              <th>{t("labProcesses.lostQuantity")}</th>
              <th>{t("labProcesses.executionDate")}</th>
              <th>{t("labProcesses.status")}</th>
              <th>{t("labProcesses.recipeDetails")}</th>
              <th>{t("labProcesses.avaliableAction")}</th>
            </tr>
          </thead>
          <tbody>
            {labProcesses.map((process) => (
              <tr key={process.lab_process_id}>
                <td>{process.recipe_name}</td>
                <td>
                  {process.mainCategoryName} - {process.childCategoryName}
                </td>
                <td>{process.labProductLotNumber}</td>
                <td>{+process.lostQuantity}</td>

                <td>{new Date(process.execution_date).toLocaleDateString()}</td>
                <td
                  className={
                    process.status == "Pending"
                      ? "bg-warning align-middle "
                      : "bg-success align-middle text-white"
                  }
                >
                  {process.status}
                </td>
                <td>
                  <button
                    className="btn btn-light "
                    onClick={() => {
                      setShowCuts(true);
                      setCuts(process.cuts);
                      setSelectedProcessStatus(process.status);
                      // console.log(process.cuts);
                    }}
                  >
                    {t("labProcesses.output")}
                  </button>
                </td>
                <td>
                  {process.status === "Pending" ? (
                    <button
                      className="btn btn-success"
                      onClick={() => addToInventory(process.lab_process_id)}
                    >
                      {t("actions.finish")}
                    </button>
                  ) : (
                    <ImCross />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showCuts && (
        <CutsModal
          setShow={setShowCuts}
          cuts={cuts}
          show={showCuts}
          selectedProcessStatus={selectedProcessStatus}
        />
      )}
    </div>
  );
}

export default LabManagement;
