import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import { useState } from "react";
import { Button, Spinner, Toast } from "react-bootstrap";
const DeliveryNotes = () => {
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state || !location.state.order) {
    return (
      <div className="container mt-4">
        <div className="alert alert-danger">
          {t("deliveryNotes.noOrderData")}
        </div>
        <button className="btn btn-primary" onClick={() => navigate("/orders")}>
          {t("deliveryNotes.backToOrders")}
        </button>
      </div>
    );
  }

  const { order } = location.state;

  const generatePDF = () => {
    setLoading(true);

    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text("Számla (Invoice)", 14, 16);
    doc.text(`Számla száma (Invoice Number): ${order.bill_number}`, 14, 24);
    doc.text(
      `Kiadás dátuma (Issue Date): ${new Date().toLocaleDateString()}`,
      14,
      32
    );
    doc.text(
      `Fizetési határidő (Due Date): ${new Date(
        order.order_date
      ).toLocaleDateString()}`,
      14,
      40
    );

    // Company Information
    doc.setFontSize(10);
    doc.text("Szállító (Supplier):", 14, 50);
    doc.text("Név (Name): Huskamra", 14, 58);
    doc.text("Cím (Address): 2000 Szentendre, Kőzúzó utca 24.", 14, 66);
    doc.text("Adószám (Tax Number): 27878275-2-13", 14, 74);
    doc.text("Telefon (Phone): +3630 111 7323", 14, 82);
    doc.text("Email: huskamra@gmail.com", 14, 90);
    doc.text("Weboldal (Website): www.huskamra.com", 14, 98);

    // Customer Information
    doc.text("Vevő (Customer):", 14, 108);
    doc.text(`Név (Name): ${order.customer_name}`, 14, 116);
    doc.text(`Cím (Address): ${order.customer_address}`, 14, 124);
    doc.text(
      `Adószám (Tax Number): ${order.customer_vat_number || ""}`,
      14,
      132
    );

    // Order Information
    doc.autoTable({
      head: [
        [
          t("orders.customer"),
          t("orders.billNumber"),
          t("orders.date"),
          t("orders.status"),
          t("orders.paymentStatus"),
        ],
      ],
      body: [
        [
          order.customer_name,
          order.bill_number,
          new Date(order.order_date).toLocaleDateString(),
          order.status,
          order.payment_status,
        ],
      ],
      startY: 140,
    });

    // Product Details
    const items = order.items.map((item) => [
      `${item.main_category_name} - ${item.child_category_name}`,
      item.lot_number,
      item.warehouse_name,
      item.quantity,
      item.unit,
      `${item.vatName} - ${item.vatValue}`,
      item.sell_price,
      item.totalPrice,
    ]);
    doc.autoTable({
      head: [
        [
          t("productView.productName"),
          t("productView.lotNumber"),
          t("products.warehouse"),
          t("productView.quantity"),
          t("products.unit"),
          t("productView.vat"),
          t("productView.sellPrice"),
          t("productView.totalPrice"),
        ],
      ],
      body: items,
      startY: doc.autoTable.previous.finalY + 10,
    });

    // Total Price
    doc.text(
      `${t("orders.totalPrice")}: ${order.totalPrice} Ft`,
      14,
      doc.autoTable.previous.finalY + 20
    );

    const fileName = `invoice_${order.bill_number}_${new Date()
      .toLocaleDateString()
      .replace(/\//g, "-")}.pdf`;
    doc.save(fileName);

    setLoading(false);
    setShowToast(true);
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h2>{t("deliveryNotes.title")}</h2>
        </div>
        <div className="card-body">
          <div className="mt-5 mb-5">
            <table class="table table-striped table-hover">
              <h2>Huskamra</h2>
              <tbody>
                <tr>
                  <th scope="row">{t("profile.address")}:</th>
                  <td>2000 Szentendre, Kőzúzó utca 24.</td>
                </tr>
                <tr>
                  <th scope="row">{t("profile.phone")}:</th>
                  <td>+3630 111 7323</td>
                </tr>
                <tr>
                  <th scope="row">{t("profile.email")}:</th>
                  <td>huskamra@gmail.com</td>
                </tr>
                <tr>
                  <th scope="row">{t("profile.website")}:</th>
                  <td>
                    <a
                      href="http://www.huskamra.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.huskamra.com
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-responsive">
            <h2 className="mt-3 mb-3">{t("orders.orderDetails")}</h2>
            <table className="table table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>{t("orders.customer")}</th>
                  <th>{t("orders.billNumber")}</th>
                  <th>{t("orders.date")}</th>
                  <th>{t("orders.status")}</th>
                  <th>{t("orders.paymentStatus")}</th>
                  <th>{t("orders.totalPrice")}</th>
                </tr>
              </thead>
              <tbody>
                <tr key={order.order_id}>
                  <td>{order.customer_name}</td>
                  <td>{order.bill_number}</td>
                  <td>{new Date(order.order_date).toLocaleDateString()}</td>
                  <td
                    className={
                      order.status == "pending"
                        ? "bg-warning"
                        : "bg-success text-white"
                    }
                  >
                    {order.status}
                  </td>
                  <td>{order.payment_status}</td>
                  <td>{order.totalPrice}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-responsive">
            <h2 className="mt-3 mb-3">{t("orders.orderDetails")}</h2>

            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>{t("productView.productName")}</th>
                  <th>{t("productView.lotNumber")}</th>
                  <th>{t("productView.vat")}</th>
                  <th>{t("productView.quantity")}</th>
                  <th>{t("productView.sellPrice")}</th>
                  <th>{t("productView.totalPrice")}</th>
                </tr>
              </thead>
              <tbody>
                {order.items.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {" "}
                      {item.main_category_name}
                      {" - "}
                      {item.child_category_name}
                    </td>
                    <td>{item.lot_number}</td>
                    <td>
                      {item.vatName}
                      {"-"}
                      {item.vatValue}
                    </td>
                    <td>{item.quantity} units</td>
                    <td>{item.sell_price}</td>
                    <td>{item.totalPrice}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Button onClick={generatePDF} disabled={loading}>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              t("orders.downloadPdf")
            )}
          </Button>

          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
            style={{ position: "fixed", bottom: 20, right: 20 }}
          >
            <Toast.Body className="bg-success text-white">
              Invoice downloaded successfully!
            </Toast.Body>
          </Toast>
        </div>
      </div>
    </div>
  );
};

export default DeliveryNotes;
