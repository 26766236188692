import React from "react";
import { useTranslation } from "react-i18next";
import { TiWorld } from "react-icons/ti";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="language-switcher">
      <TiWorld
        color="#014D99"
        style={{ fontSize: "2rem" }}
        onClick={() => {
          i18n.language == "en"
            ? i18n.changeLanguage("hu")
            : i18n.changeLanguage("en");
        }}
      />
      {/* <select onChange={changeLanguage} value={i18n.language}>
        <option value="en">English</option>
        <option value="hu">Magyar</option>{" "}
      </select> */}
    </div>
  );
}

export default LanguageSwitcher;
