import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaPlus, FaSave } from "react-icons/fa";
import { ImCross } from "react-icons/im";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

function AddProductsForm({ isOpen, onClose, onSave, initalData }) {
  const [products, setProducts] = useState([
    {
      main_category_id: initalData?.mainCategoryId || "",
      category_id: initalData?.categoryId || "",
      warehouse_id: "",
      purchase_price: "",
      stock_quantity: initalData?.quantity || "",
      lot_number: initalData?.cutLotNumber || "",
      arrival_date: "",
      expiration_date: "",
      unit: "",
      vat_id: "",
      registration_src: initalData ? "lab_process" : "admin",
      bill_number: initalData?.bill_number || "",
      product_id: initalData?.product_id || "",
      lostQuantity: initalData?.lostQuantity || "",
    },
  ]);
  const [mainCategories, setMainCategories] = useState([]);
  const [childCategories, setChildCategories] = useState({});
  const [warehouses, setWarehouses] = useState([]);
  const [units, setUnits] = useState([]);
  const [vatRates, setVatRates] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [supplierId, setSupplierId] = useState("");
  const [billNumber, setBillNumber] = useState(initalData?.bill_number || "");
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      fetchSuppliers();
      fetchCategories();
      fetchActiveWarehouses();
      fetchUnits();
      fetchVatRates();
    }
  }, [isOpen]);

  const fetchSuppliers = async () => {
    try {
      const response = await axios.get("/suppliers");
      setSuppliers(response.data);
    } catch (error) {
      console.error("Failed to fetch suppliers:", error);
      setSuppliers([]);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get("/setup/categories");
      const categories = response.data;
      const mainCategories = categories.filter((cat) => !cat.parent_id);
      const childCategoriesMap = {};

      categories.forEach((cat) => {
        if (cat.parent_id) {
          if (!childCategoriesMap[cat.parent_id]) {
            childCategoriesMap[cat.parent_id] = [];
          }
          childCategoriesMap[cat.parent_id].push(cat);
        }
      });

      setMainCategories(mainCategories);
      setChildCategories(childCategoriesMap);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  const fetchActiveWarehouses = async () => {
    try {
      const response = await axios.get("/setup/warehouses/active");
      setWarehouses(response.data);
    } catch (error) {
      console.error("Failed to fetch warehouses:", error);
    }
  };

  const fetchUnits = async () => {
    try {
      const response = await axios.get("/setup/units");
      setUnits(response.data);
    } catch (error) {
      console.error("Failed to fetch units:", error);
    }
  };

  const fetchVatRates = async () => {
    try {
      const response = await axios.get("/setup/vat-rates");
      setVatRates(response.data);
    } catch (error) {
      console.error("Failed to fetch VAT rates:", error);
    }
  };

  const handleProductChange = (index, event) => {
    const { name, value } = event.target;
    const newProducts = [...products];
    newProducts[index][name] = value;
    setProducts(newProducts);

    if (name === "main_category_id") {
      const updatedProducts = [...products];
      updatedProducts[index].category_id = ""; // Reset child category selection
      setProducts(updatedProducts);
    }
  };

  const handleAddProduct = () => {
    setProducts([
      ...products,
      {
        main_category_id: "",
        category_id: "",
        warehouse_id: "",
        purchase_price: "",
        stock_quantity: "",
        lot_number: "",
        arrival_date: "",
        expiration_date: "",
        unit: "",
        vat_id: "",
        registration_src: "admin",
      },
    ]);
  };

  const handleRemoveProduct = (index) => {
    const newProducts = products.filter((_, i) => i !== index);
    setProducts(newProducts);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = { products, supplier_id: supplierId, bill_number: billNumber };
    if (initalData?.lab_process_id) {
      data = { ...data, lab_process_id: initalData.lab_process_id };
    }
    onSave(data);
    onClose();
  };

  return (
    <div
      className={`modal modal-xl fade ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t("addProductsModal.title")}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} id="form-100">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("addProductsModal.supplierId")}:</label>
                      <select
                        name="supplier_id"
                        className="form-control"
                        value={supplierId}
                        onChange={(e) => setSupplierId(e.target.value)}
                        required
                      >
                        <option value="">
                          {t("addProductsModal.selectSupplier")}
                        </option>
                        {Array.isArray(suppliers) &&
                          suppliers.map((supplier) => (
                            <option
                              key={supplier.supplier_id}
                              value={supplier.supplier_id}
                            >
                              {supplier.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("addProductsModal.billNumber")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="bill_number"
                        value={billNumber}
                        onChange={(e) => setBillNumber(e.target.value)}
                        required
                        disabled={!!initalData}
                      />
                    </div>
                  </div>
                </div>

                {products.map((product, index) => (
                  <div key={index} className="row bg-ccc">
                    {/* Main Category and Child Category */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("addProductsModal.mainCategory")}:</label>
                        <select
                          name="main_category_id"
                          className="form-control"
                          value={product.main_category_id}
                          onChange={(e) => handleProductChange(index, e)}
                          required
                          disabled={!!initalData}
                        >
                          <option value="">
                            {t("addProductsModal.selectMainCategory")}
                          </option>
                          {mainCategories.map((mainCat) => (
                            <option
                              key={mainCat.category_id}
                              value={mainCat.category_id}
                            >
                              {mainCat.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("addProductsModal.childCategory")}:</label>
                        <select
                          name="category_id"
                          className="form-control"
                          value={product.category_id}
                          onChange={(e) => handleProductChange(index, e)}
                          required
                          disabled={!!initalData}
                        >
                          <option value="">
                            {t("addProductsModal.selectChildCategory")}
                          </option>
                          {childCategories[product.main_category_id] &&
                            childCategories[product.main_category_id].map(
                              (childCat) => (
                                <option
                                  key={childCat.category_id}
                                  value={childCat.category_id}
                                >
                                  {childCat.name}
                                </option>
                              )
                            )}
                        </select>
                      </div>
                    </div>

                    {/* Warehouse and Unit */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("addProductsModal.warehouse")}:</label>
                        <select
                          name="warehouse_id"
                          className="form-control"
                          value={product.warehouse_id}
                          onChange={(e) => handleProductChange(index, e)}
                          required
                        >
                          <option value="">
                            {t("addProductsModal.selectWarehouse")}
                          </option>
                          {warehouses.map((warehouse) => (
                            <option
                              key={warehouse.warehouse_id}
                              value={warehouse.warehouse_id}
                            >
                              {warehouse.name +
                                " - " +
                                (+warehouse.capacity -
                                  +warehouse.loaded_capacity)}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("addProductsModal.unit")}:</label>
                        <select
                          name="unit"
                          className="form-control"
                          value={product.unit}
                          onChange={(e) => handleProductChange(index, e)}
                          required
                        >
                          <option value="">
                            {t("addProductsModal.selectUnit")}
                          </option>
                          {units.map((unit) => (
                            <option key={unit.unit_id} value={unit.name}>
                              {unit.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Purchase Price and Stock Quantity */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("addProductsModal.purchasePrice")}:</label>
                        <input
                          type="number"
                          className="form-control"
                          name="purchase_price"
                          value={product.purchase_price}
                          onChange={(e) => handleProductChange(index, e)}
                          required
                        />
                        <input
                          type="hidden"
                          className="form-control"
                          name="registration_src"
                          value={product.registration_src}
                          onChange={(e) => handleProductChange(index, e)}
                          required
                        />
                        {product.product_id && (
                          <input
                            type="hidden"
                            className="form-control"
                            name="product_id"
                            value={product.product_id}
                            onChange={(e) => handleProductChange(index, e)}
                            required
                          />
                        )}
                        {product.lostQuantity && (
                          <input
                            type="hidden"
                            className="form-control"
                            name="lost_quantity"
                            value={product.product_id}
                            onChange={(e) => handleProductChange(index, e)}
                            required
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("addProductsModal.stockQuantity")}:</label>
                        <input
                          type="number"
                          className="form-control"
                          name="stock_quantity"
                          value={product.stock_quantity}
                          onChange={(e) => handleProductChange(index, e)}
                          required
                          disabled={!!initalData}
                        />
                      </div>
                    </div>

                    {/* Lot Number, Arrival Date, and Expiration Date */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("addProductsModal.lotNumber")}:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lot_number"
                          value={product.lot_number}
                          onChange={(e) => handleProductChange(index, e)}
                          disabled={!!initalData}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>{t("addProductsModal.arrivalDate")}:</label>
                        <input
                          type="date"
                          className="form-control"
                          name="arrival_date"
                          value={
                            product.arrival_date
                              ? product.arrival_date.split("T")[0]
                              : ""
                          }
                          onChange={(e) => handleProductChange(index, e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>{t("addProductsModal.expirationDate")}:</label>
                        <input
                          type="date"
                          className="form-control"
                          name="expiration_date"
                          value={
                            product.expiration_date
                              ? product.expiration_date.split("T")[0]
                              : ""
                          }
                          onChange={(e) => handleProductChange(index, e)}
                        />
                      </div>
                    </div>

                    {/* VAT Rate and Remove Product Button */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{t("addProductsModal.vatRate")}:</label>
                        <select
                          name="vat_id"
                          className="form-control"
                          value={product.vat_id}
                          onChange={(e) => handleProductChange(index, e)}
                          required
                        >
                          <option value="">
                            {t("addProductsModal.selectVatRate")}
                          </option>
                          {vatRates.map((rate) => (
                            <option key={rate.vat_id} value={rate.vat_id}>
                              {rate.name} ({rate.rate}%)
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-end">
                      {!initalData && (
                        <button
                          type="button"
                          className="btn btn-danger w-100"
                          onClick={() => handleRemoveProduct(index)}
                        >
                          <ImCross />
                          <span className="text-icon ms-2">
                            {t("addProductsModal.removeProduct")}
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                ))}

                <div className="row mt-3">
                  <div className="col-md-6 d-flex justify-content-start">
                    {!initalData && (
                      <button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={handleAddProduct}
                      >
                        <FaPlus />
                        <span className="text-icon ms-2">
                          {t("addProductsModal.addProduct")}
                        </span>
                      </button>
                    )}
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-success w-100 me-2"
                    >
                      <FaSave />
                      <span className="text-icon ms-2">
                        {t("addProductsModal.saveButton")}
                      </span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger w-100"
                      onClick={onClose}
                    >
                      <ImCross />
                      <span className="text-icon ms-2">
                        {t("addProductsModal.cancelButton")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProductsForm;
