import React, { useState, useEffect } from "react";
import axios from "axios";
import UnitForm from "./UnitForm";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash } from "react-icons/fa";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

function Units() {
  const [units, setUnits] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentUnit, setCurrentUnit] = useState({ name: "", description: "" });
  const { t } = useTranslation();

  useEffect(() => {
    fetchUnits();
  }, []);

  const fetchUnits = async () => {
    try {
      const response = await axios.get("/setup/units");
      setUnits(response.data);
    } catch (error) {
      console.error(t("units.fetchError"), error);
    }
  };

  const handleAddOrUpdateUnit = async (unitData) => {
    const method = unitData.unit_id ? "put" : "post";
    const url = unitData.unit_id
      ? `/setup/units/${unitData.unit_id}`
      : "/setup/units";
    try {
      const response = await axios[method](url, unitData);
      console.log(
        unitData.unit_id ? "Unit updated" : "Unit added",
        response.data
      );
      fetchUnits();
    } catch (error) {
      console.error(
        "Error updating/adding unit:",
        error.response ? error.response.data : error
      );
      alert(
        `Error updating unit: ${
          error.response
            ? JSON.stringify(error.response.data)
            : "Server not responding"
        }`
      );
    }
    closeModal();
  };

  const handleDeleteUnit = async (unitId) => {
    try {
      await axios.delete(`/setup/units/${unitId}`);
      fetchUnits();
    } catch (error) {
      console.error(t("units.deleteError"), error);
    }
  };

  const openModalToAdd = () => {
    setCurrentUnit({ name: "", description: "" });
    setModalIsOpen(true);
  };

  const openModalToEdit = (unit) => {
    setCurrentUnit(unit);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentUnit({ name: "", description: "" });
  };

  return (
    <div className="container-fluid ">
      <h2>{t("units.title")}</h2>
      <button
        className="btn btn-primary my-3 w-29-5 text-center"
        onClick={openModalToAdd}
      >
        {t("units.addNew")}
      </button>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("units.name")}</th>
              <th>{t("units.description")}</th>
              <th>{t("units.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {units.map((unit) => (
              <tr key={unit.unit_id}>
                <td>{unit.name}</td>
                <td>{unit.description}</td>
                <td className="d-flex ">
                  <button
                    className="btn btn-success m-1"
                    onClick={() => openModalToEdit(unit)}
                  >
                    <FaEdit />
                    <span className="text-icon">{t("units.edit")}</span>
                  </button>
                  <button
                    className="btn btn-danger m-1"
                    onClick={() => handleDeleteUnit(unit.unit_id)}
                  >
                    <FaTrash />
                    <span className="text-icon">{t("units.delete")}</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalIsOpen && (
        <UnitForm
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSave={handleAddOrUpdateUnit}
          initialData={currentUnit}
        />
      )}
    </div>
  );
}

export default Units;
