import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

function Register() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
  });
  const [message, setMessage] = useState({ type: "", content: "" });
  const [isSubmitting, setIsSubmitting] = useState(false); // To manage the submit button state

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({});
    if (!formData.username || !formData.password || !formData.email) {
      setMessage({ type: "danger", content: t("auth.fieldCannotBeEmpty") });
      return;
    }
    if (formData.password.length < 6) {
      setMessage({
        type: "danger",
        content: t("auth.passwordMustBeAtLeast6CharactersLong"),
      });
      return;
    }

    setIsSubmitting(true); // Disable the button while processing
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/register`,
        formData
      );
      setMessage({
        type: "success",
        content: t("auth.userCreatedPleaseLoginNow"),
      });
    } catch (error) {
      setMessage({ type: "danger", content: t("auth.loginError") });
    } finally {
      setIsSubmitting(false); // Re-enable the button once processing is complete
    }
  };

  return (
    <div>
      <h2 className="text-center mt-5 mb-5">{t("auth.register")}</h2>
      <form onSubmit={handleSubmit}>
        <input
          className="w-100"
          type="text"
          name="username"
          value={formData.username}
          onChange={handleFormChange}
          placeholder={t("auth.userNameLabel")}
          disabled={isSubmitting}
        />
        <input
          className="w-100"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleFormChange}
          placeholder={t("auth.emailLabel")}
          disabled={isSubmitting}
        />
        <input
          className="w-100"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleFormChange}
          placeholder={t("auth.passwordLabel")}
          disabled={isSubmitting}
        />
        <button type="submit" disabled={isSubmitting}>
          {t("auth.register")}
        </button>
      </form>
      {message.type && message.content && (
        <p className={"text-center " + "text-" + message.type}>
          {message.content}
        </p>
      )}
    </div>
  );
}

export default Register;
