import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FaSave, FaPlus, FaTrash } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import "bootstrap/dist/css/bootstrap.min.css";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

function AddProductsForm({ isOpen, onClose, onSave }) {
  const [products, setProducts] = useState([
    {
      main_category_id: "",
      category_id: "",
      unit: "",
    },
  ]);
  const [mainCategories, setMainCategories] = useState([]);
  const [childCategories, setChildCategories] = useState({});
  const [units, setUnits] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      fetchCategories();
      fetchUnits();
    }
  }, [isOpen]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("/setup/categories");
      const categories = response.data;
      const mainCategories = categories.filter((cat) => !cat.parent_id);
      const childCategoriesMap = {};

      categories.forEach((cat) => {
        if (cat.parent_id) {
          if (!childCategoriesMap[cat.parent_id]) {
            childCategoriesMap[cat.parent_id] = [];
          }
          childCategoriesMap[cat.parent_id].push(cat);
        }
      });

      setMainCategories(mainCategories);
      setChildCategories(childCategoriesMap);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  const fetchUnits = async () => {
    try {
      const response = await axios.get("/setup/units");
      setUnits(response.data);
    } catch (error) {
      console.error("Failed to fetch units:", error);
    }
  };

  const handleProductChange = (index, event) => {
    const { name, value } = event.target;
    const newProducts = [...products];
    newProducts[index][name] = value;
    setProducts(newProducts);

    if (name === "main_category_id") {
      const updatedProducts = [...products];
      updatedProducts[index].category_id = ""; // Reset child category selection
      setProducts(updatedProducts);
    }
  };

  const handleAddProduct = () => {
    setProducts([
      ...products,
      {
        main_category_id: "",
        category_id: "",
        unit: "",
      },
    ]);
  };

  const handleRemoveProduct = (index) => {
    const newProducts = products.filter((_, i) => i !== index);
    setProducts(newProducts);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    onSave({ products });
    onClose();
  };

  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t("addProductsModal.title")}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} id="form-100">
              {products.map((product, index) => (
                <div key={index} className="mb-5 bg-ccc">
                  <div className="row">
                    <div className="col-6">
                      <label>{t("addProductsModal.mainCategory")}:</label>
                      <select
                        className="form-select"
                        name="main_category_id"
                        value={product.main_category_id}
                        onChange={(e) => handleProductChange(index, e)}
                        required
                      >
                        <option value="">
                          {t("addProductsModal.selectMainCategory")}
                        </option>
                        {mainCategories.map((mainCat) => (
                          <option
                            key={mainCat.category_id}
                            value={mainCat.category_id}
                          >
                            {mainCat.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-6">
                      <label>{t("addProductsModal.childCategory")}:</label>
                      <select
                        className="form-select"
                        name="category_id"
                        value={product.category_id}
                        onChange={(e) => handleProductChange(index, e)}
                        required
                      >
                        <option value="">
                          {t("addProductsModal.selectChildCategory")}
                        </option>
                        {childCategories[product.main_category_id] &&
                          childCategories[product.main_category_id].map(
                            (childCat) => (
                              <option
                                key={childCat.category_id}
                                value={childCat.category_id}
                              >
                                {childCat.name}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <label>{t("addProductsModal.unit")}:</label>
                      <select
                        className="form-select"
                        name="unit"
                        value={product.unit}
                        onChange={(e) => handleProductChange(index, e)}
                        required
                      >
                        <option value="">
                          {t("addProductsModal.selectUnit")}
                        </option>
                        {units.map((unit) => (
                          <option key={unit.unit_id} value={unit.unit_id}>
                            {unit.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-6 d-flex align-items-end">
                      <button
                        type="button"
                        className="btn btn-danger mt-2"
                        onClick={() => handleRemoveProduct(index)}
                      >
                        <FaTrash />
                        <span className="ms-2">
                          {t("addProductsModal.removeProduct")}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}

              <div className="d-flex w-100 justify-content-between mt-5">
                <div className="w-100">
                  <button
                    type="submit"
                    className="btn btn-success w-25 me-4 form-control"
                  >
                    <FaSave />
                    <span className="ms-2">
                      {t("addProductsModal.saveButton")}
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary me-4 form-contro w-25"
                    onClick={handleAddProduct}
                  >
                    <FaPlus />
                    <span className="ms-2">
                      {t("addProductsModal.addProduct")}
                    </span>
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-danger w-50"
                  onClick={onClose}
                >
                  <ImCross />
                  <span className="ms-2">
                    {t("addProductsModal.cancelButton")}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProductsForm;
