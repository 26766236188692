import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthProvider";
import { useTranslation } from "react-i18next";

function Login() {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({ username: "", password: "" });
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { setToken } = useContext(AuthContext);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/login`,
        formData
      );
      if (response.data.token) {
        setToken(response.data.token); // Use setToken from AuthContext
        setIsLoading(false);
      } else {
        setMessage(t("auth.loginError"));
        setIsLoading(false);
      }
    } catch (error) {
      setMessage(t("auth.loginError"));
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2 className="text-center mb-5 mt-5">{t("auth.login")}</h2>
      <form onSubmit={handleSubmit}>
        <input
          className="w-100"
          type="text"
          name="username"
          value={formData.username}
          onChange={handleFormChange}
          placeholder={t("auth.userNameLabel")}
          disabled={isLoading}
        />
        <input
          className="w-100"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleFormChange}
          placeholder={t("auth.passwordLabel")}
          disabled={isLoading}
        />
        <button type="submit" disabled={isLoading}>
          {t("auth.login")}
        </button>
      </form>
      {message && <p className="text-center text-danger">{message}</p>}
      {isLoading && <p>Loading...</p>}
    </div>
  );
}

export default Login;
