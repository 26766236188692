import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { ImCross } from "react-icons/im";

function EditCustomerModal({
  isOpen,
  onClose,
  onSave,
  initialData,
  paymentMethods,
  currencies,
}) {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    email: "",
    vat_number: "",
    phone: "",
    bank_account: "",
    payment_method: "",
    currency: "",
    contact_person: "",
    contact_email: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setFormData({
        name: initialData?.name || "",
        address: initialData?.address || "",
        email: initialData?.email || "",
        vat_number: initialData?.vat_number || "",
        phone: initialData?.phone || "",
        bank_account: initialData?.bank_account || "",
        payment_method: initialData?.payment_method || "",
        currency: initialData?.currency || "",
        contact_person: initialData?.contact_person || "",
        contact_email: initialData?.contact_email || "",
      });
    }
  }, [initialData, isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          {initialData
            ? t("editCustomerModal.editTitle")
            : t("editCustomerModal.addTitle")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} id="form-100">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>{t("editCustomerModal.name")}:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label>{t("editCustomerModal.address")}:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label>{t("editCustomerModal.email")}:</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label>{t("editCustomerModal.vatNumber")}:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="vat_number"
                    value={formData.vat_number}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label>{t("editCustomerModal.phone")}:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label>{t("editCustomerModal.bankAccount")}:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="bank_account"
                    value={formData.bank_account}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label>{t("editCustomerModal.paymentMethod")}:</label>
                  <select
                    className="form-control"
                    name="payment_method"
                    value={formData.payment_method}
                    onChange={handleChange}
                    required
                  >
                    <option value="">
                      {t("editCustomerModal.selectPaymentMethod")}
                    </option>
                    {paymentMethods.map((method) => (
                      <option
                        key={method.method_name}
                        value={method.method_name}
                      >
                        {method.method_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label>{t("editCustomerModal.currency")}:</label>
                  <select
                    className="form-control"
                    name="currency"
                    value={formData.currency}
                    onChange={handleChange}
                    required
                  >
                    <option value="">
                      {t("editCustomerModal.selectCurrency")}
                    </option>
                    {currencies.map((currency) => (
                      <option
                        key={currency.currency_code}
                        value={currency.currency_code}
                      >
                        {currency.currency_code}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label>{t("editCustomerModal.contactPerson")}:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="contact_person"
                    value={formData.contact_person}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label>{t("editCustomerModal.contactEmail")}:</label>
                  <input
                    type="email"
                    className="form-control"
                    name="contact_email"
                    value={formData.contact_email}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <Button type="submit" variant="success" className="m-2">
              <FaSave />
              <span className="text-icon">
                {t("editCustomerModal.saveButton")}
              </span>
            </Button>
            <Button variant="danger" className="m-2" onClick={onClose}>
              <ImCross />
              <span className="text-icon">
                {t("editCustomerModal.cancelButton")}
              </span>
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EditCustomerModal;
