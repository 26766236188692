import React, { useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const OrderItemViewModal = ({ items, handleClose, show }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal className="modal-xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t("productView.productName")}</th>
                <th>{t("productView.lotNumber")}</th>
                <th>{t("productView.vat")}</th>
                <th>{t("productView.quantity")}</th>
                <th>{t("productView.sellPrice")}</th>
                <th>{t("productView.totalPrice")}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>
                    {" "}
                    {item.main_category_name}
                    {" - "}
                    {item.child_category_name}
                  </td>
                  <td>{item.lot_number}</td>
                  <td>
                    {item.vatName}
                    {"-"}
                    {item.vatValue}
                  </td>
                  <td>{item.quantity} units</td>
                  <td>{item.sell_price}</td>
                  <td>{item.totalPrice}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderItemViewModal;
