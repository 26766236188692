import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import LanguageSwitcher from "./i18n/LanguageSwitcher";
import { useAuth } from "./components/AuthProvider";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export default function NavBar() {
  const { token, logout } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();

  const navItemsAuth = [
    { path: "/setup", label: t("nav.setup") },
    { path: "/suppliers", label: t("nav.suppliers") },
    { path: "/products", label: t("nav.products") },
    { path: "/inventory", label: t("nav.inventory") },
    { path: "/lab", label: t("nav.lab") },
    { path: "/customers", label: t("nav.customers") },
    { path: "/orders", label: t("nav.orders") },
    { path: "/profile", label: t("nav.profile") },
    { path: "/expenses", label: t("nav.expenses") },
    { path: "/expenses-summary", label: t("nav.expensesSummary") },
  ];
  const navItemsNotAuth = [
    { path: "/login", label: t("nav.login") },
    { path: "/register", label: t("nav.register") },
  ];
  return (
    <Container fluid className="p-2">
      <Navbar.Brand>Henteuszem</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto w-100">
          {token ? (
            <>
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex">
                  {navItemsAuth.map((item) => (
                    <Nav.Link
                      as={Link}
                      to={item.path}
                      key={item.path}
                      className={
                        location.pathname === item.path
                          ? "btn btn-primary active-nav-path"
                          : "btn btn-secoundry pending-nav-path"
                      }
                    >
                      {item.label}
                    </Nav.Link>
                  ))}
                </div>
                <div className="d-flex">
                  <LanguageSwitcher />
                  <Nav.Link onClick={logout} className="btn btn-danger-logout">
                    {t("nav.logout")}
                  </Nav.Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex">
                  {navItemsNotAuth.map((item) => (
                    <Nav.Link
                      as={Link}
                      to={item.path}
                      key={item.path}
                      className={
                        location.pathname === item.path
                          ? "btn btn-primary active-nav-path"
                          : "btn btn-secoundry pending-nav-path"
                      }
                    >
                      {item.label}
                    </Nav.Link>
                  ))}
                </div>
                <LanguageSwitcher />
              </div>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Container>
  );
}
