import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaSave } from "react-icons/fa";
import { ImCross } from "react-icons/im";

function WarehouseForm({ isOpen, onClose, onSave, initialData }) {
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    capacity: "",
    manager: "",
    contact_details: "",
    status: "Active",
    temperature: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setFormData({
        name: initialData?.name || "",
        location: initialData?.location || "",
        capacity: initialData?.capacity || "",
        manager: initialData?.manager || "",
        contact_details: initialData?.contact_details || "",
        status: initialData?.status || "Active",
        temperature: initialData?.temperature || "",
        warehouse_id: initialData?.warehouse_id || null,
      });
    }
  }, [initialData, isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      warehouse_id: formData.warehouse_id,
      name: formData.name,
      location: formData.location,
      capacity: formData.capacity,
      manager: formData.manager,
      contact_details: formData.contact_details,
      status: formData.status,
      temperature: formData.temperature,
    });
    onClose();
  };

  return (
    <div
      className={`modal modal-xl fade ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {formData.warehouse_id
                ? t("warehouseForm.editTitle")
                : t("warehouseForm.addTitle")}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} id="form-100">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("warehouseForm.name")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("warehouseForm.location")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="location"
                        value={formData.location}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("warehouseForm.capacity")}:</label>
                      <input
                        type="number"
                        className="form-control"
                        name="capacity"
                        value={formData.capacity}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("warehouseForm.manager")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="manager"
                        value={formData.manager}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>{t("warehouseForm.contactDetails")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="contact_details"
                        value={formData.contact_details}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("warehouseForm.status")}:</label>
                      <select
                        name="status"
                        className="form-control select-input"
                        value={formData.status}
                        onChange={handleInputChange}
                      >
                        <option value="Active">
                          {t("warehouseForm.active")}
                        </option>
                        <option value="Inactive">
                          {t("warehouseForm.inactive")}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("warehouseForm.temperature")}:</label>
                      <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        name="temperature"
                        value={formData.temperature}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-3 d-flex justify-content-between">
                  <div className="col-md-6 d-flex justify-content-start">
                    <button type="submit" className="btn btn-success w-50 me-2">
                      <FaSave />
                      <span className="text-icon ms-2">
                        {t("warehouseForm.saveButton")}
                      </span>
                    </button>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-danger w-50"
                      onClick={onClose}
                    >
                      <ImCross />
                      <span className="text-icon ms-2">
                        {t("warehouseForm.cancelButton")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WarehouseForm;
