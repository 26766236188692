import React, { useState, useEffect } from "react";
import axios from "axios";
import ExpenseCategoryForm from "./ExpenseCategoryForm";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash } from "react-icons/fa";

function ExpenseCategories() {
  const [categories, setCategories] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/setup/expense-categories`
      );
      setCategories(response.data || []);
    } catch (error) {
      console.error(t("expenseCategories.fetchError"), error);
    }
  };

  const handleAddOrUpdateCategory = async (categoryData) => {
    const method = categoryData.category_id ? "put" : "post";
    const url = categoryData.category_id
      ? `${process.env.REACT_APP_API_URL}/setup/expense-categories/${categoryData.category_id}`
      : `${process.env.REACT_APP_API_URL}/setup/expense-categories`;
    try {
      await axios[method](url, categoryData);
      fetchCategories();
    } catch (error) {
      console.error(
        "Error updating/adding category:",
        error.response ? error.response.data : error
      );
      alert(
        `Error updating category: ${
          error.response
            ? JSON.stringify(error.response.data)
            : "Server not responding"
        }`
      );
    }
    closeModal();
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/setup/expense-categories/${categoryId}`
      );
      fetchCategories();
    } catch (error) {
      console.error(t("expenseCategories.deleteError"), error);
    }
  };

  const openModalToAdd = () => {
    setCurrentCategory({
      main_category: "",
      sub_category: "",
      description: "",
    });
    setModalIsOpen(true);
  };

  const openModalToEdit = (category) => {
    const { category_id, main_category, sub_category, description } = category;
    setCurrentCategory({
      category_id,
      main_category,
      sub_category,
      description,
    });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentCategory(null);
  };

  return (
    <div className="container-fluid ">
      <h2>{t("expenseCategories.title")}</h2>
      <button
        className="btn btn-primary my-3 w-29-5 text-center"
        onClick={openModalToAdd}
      >
        {t("expenseCategories.addNew")}
      </button>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("expenseCategories.mainCategory")}</th>
              <th>{t("expenseCategories.subCategory")}</th>
              <th>{t("expenseCategories.description")}</th>
              <th>{t("expenseCategories.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category, index) => (
              <tr key={category.category_id + index}>
                <td>{category.main_category}</td>
                <td>{category.sub_category}</td>
                <td>{category.description}</td>
                <td className="d-flex ">
                  <button
                    className="btn btn-success m-1"
                    onClick={() => openModalToEdit(category)}
                  >
                    <FaEdit />
                    <span className="text-icon">
                      {t("expenseCategories.edit")}
                    </span>
                  </button>
                  <button
                    className="btn btn-danger m-1"
                    onClick={() => handleDeleteCategory(category.category_id)}
                  >
                    <FaTrash />
                    <span className="text-icon">
                      {t("expenseCategories.delete")}
                    </span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalIsOpen && (
        <ExpenseCategoryForm
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSave={handleAddOrUpdateCategory}
          initialData={
            currentCategory || {
              main_category: "",
              sub_category: "",
              description: "",
            }
          }
        />
      )}
    </div>
  );
}

export default ExpenseCategories;
