import React from 'react';
import { useAuth } from './AuthProvider';
import { useTranslation } from 'react-i18next';

function UserProfile() {
    const { token, logout } = useAuth();
    const { t } = useTranslation();

    return (
        <div className="container mt-4">
            <div className="card">
                <div className="card-header">
                    <h2>{t('profile.title')}</h2>
                </div>
                <div className="card-body">
                    <h3>Hús-Kamra Kft</h3>
                    <p>{t('profile.description')}</p>
                    <ul>
                        <li>{t('profile.address')}: 2000 Szentendre, Kőzúzó utca 24.</li>
                        <li>{t('profile.phone')}: +3630 111 7323</li>
                        <li>{t('profile.email')}: huskamra@gmail.com</li>
                        <li>{t('profile.website')}: <a href="http://www.huskamra.com" target="_blank" rel="noopener noreferrer">www.huskamra.com</a></li>
                    </ul>
                    {token ? (
                        <button className="btn btn-danger" onClick={logout}>{t('nav.logout')}</button>
                    ) : (
                        <p>{t('profile.pleaseLogin')}</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UserProfile;
