import React, { useState, useEffect } from "react";
import axios from "axios";
import AddProductsForm from "./AddProductsForm";
import EditProductForm from "./EditProductForm";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash } from "react-icons/fa";

function Products() {
  const [products, setProducts] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const { t } = useTranslation();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`
      );
      setProducts(response.data);
      setError("");
    } catch (error) {
      console.error("Failed to fetch products:", error);
      setError(t("errors.fetchFailed"));
    }
  };

  const handleAddOrUpdateProduct = async (productData) => {
    const apiEndpoint = currentProduct
      ? `${process.env.REACT_APP_API_URL}/products/${currentProduct.product_id}`
      : `${process.env.REACT_APP_API_URL}/products`;
    try {
      const method = currentProduct ? "put" : "post";
      await axios[method](apiEndpoint, productData);
      fetchProducts();
      closeModal();
    } catch (error) {
      console.error("Failed to add or update product:", error);
      setError(t("errors.updateFailed"));
    }
  };

  const handleDeleteProduct = async (productId) => {
    const confirmDelete = window.confirm(t("confirmDelete"));
    if (!confirmDelete) return;

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/products/${productId}`
      );
      fetchProducts();
    } catch (error) {
      console.error("Failed to delete product:", error);
      setError(t("errors.deleteFailed"));
    }
  };

  const openModalToAdd = () => {
    setCurrentProduct(null);
    setModalIsOpen(true);
  };

  const openModalToEdit = (product) => {
    setCurrentProduct(product);
    setEditModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditModalIsOpen(false);
    setCurrentProduct(null);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredProducts = products.filter((product) => {
    return (
      product.main_category_name.toLowerCase().includes(searchQuery) ||
      product.child_category_name.toLowerCase().includes(searchQuery) ||
      product.unit.toLowerCase().includes(searchQuery)
    );
  });

  return (
    <div className="container-fluid mt-4">
      <h2>{t("products.title")}</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="d-flex justify-content-between">
        <button
          className="btn btn-primary my-3 w-29-5 text-center"
          onClick={openModalToAdd}
        >
          {t("products.addNew")}
        </button>
        <input
          type="text"
          className="form-control mb-3 w-25 h-75"
          placeholder={t("products.search")}
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <div className="table-responsive">
        <table className="table table-bordered table-striped">
          <thead className="thead-dark">
            <tr>
              <th>{t("products.mainCategory")}</th>
              <th>{t("products.childCategory")}</th>
              <th>{t("products.unit")}</th>
              <th>{t("products.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product) => (
              <tr key={product.product_id}>
                <td>{product.main_category_name}</td>
                <td>{product.child_category_name}</td>
                <td>{product.unit}</td>
                <td className="d-flex">
                  <button
                    className="btn btn-success m-1 d-flex align-items-center"
                    onClick={() => openModalToEdit(product)}
                  >
                    <FaEdit />
                    <span className="text-icon"> {t("products.edit")}</span>
                  </button>
                  <button
                    className="btn btn-danger m-1 d-flex align-items-center"
                    onClick={() => handleDeleteProduct(product.product_id)}
                  >
                    <FaTrash />
                    <span className="text-icon">{t("products.delete")}</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalIsOpen && (
        <AddProductsForm
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSave={handleAddOrUpdateProduct}
        />
      )}
      {editModalIsOpen && (
        <EditProductForm
          isOpen={editModalIsOpen}
          onClose={closeModal}
          onSave={handleAddOrUpdateProduct}
          initialData={currentProduct}
        />
      )}
    </div>
  );
}

export default Products;
