import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import "bootstrap/dist/css/bootstrap.min.css";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

function EditProductForm({ isOpen, onClose, onSave, initialData }) {
  const [formData, setFormData] = useState({
    ...initialData,
    unit: initialData.unit_id,
  });

  const [mainCategories, setMainCategories] = useState([]);
  const [childCategories, setChildCategories] = useState({});
  const [units, setUnits] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (isOpen) {
      fetchCategories();
      fetchUnits();
    }
  }, [isOpen]);
  const fetchCategories = async () => {
    try {
      const response = await axios.get("/setup/categories");
      const categories = response.data;
      const mainCategories = categories.filter((cat) => !cat.parent_id);
      const childCategoriesMap = {};

      categories.forEach((cat) => {
        if (cat.parent_id) {
          if (!childCategoriesMap[cat.parent_id]) {
            childCategoriesMap[cat.parent_id] = [];
          }
          childCategoriesMap[cat.parent_id].push(cat);
        }
      });

      setMainCategories(mainCategories);
      setChildCategories(childCategoriesMap);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  const fetchUnits = async () => {
    try {
      const response = await axios.get("/setup/units");
      setUnits(response.data);
    } catch (error) {
      console.error("Failed to fetch units:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "main_category_id") {
      setFormData((prev) => ({ ...prev, category_id: "" })); // Reset child category selection
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    onSave(formData);
    onClose();
  };

  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t("editProductModal.title")}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} id="form-100">
              <div className="mb-3">
                <label>{t("editProductModal.mainCategory")}:</label>
                <select
                  className="form-select"
                  name="main_category_id"
                  value={formData.main_category_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">
                    {t("editProductModal.selectMainCategory")}
                  </option>
                  {mainCategories.map((mainCat) => (
                    <option
                      key={mainCat.category_id}
                      value={mainCat.category_id}
                    >
                      {mainCat.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label>{t("editProductModal.childCategory")}:</label>
                <select
                  className="form-select"
                  name="category_id"
                  value={formData.category_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">
                    {t("editProductModal.selectChildCategory")}
                  </option>
                  {childCategories[formData.main_category_id] &&
                    childCategories[formData.main_category_id].map(
                      (childCat) => (
                        <option
                          key={childCat.category_id}
                          value={childCat.category_id}
                        >
                          {childCat.name}
                        </option>
                      )
                    )}
                </select>
              </div>

              <div className="mb-3">
                <label>{t("editProductModal.unit")}:</label>
                <select
                  className="form-select"
                  name="unit"
                  value={formData.unit}
                  onChange={handleChange}
                  required
                >
                  <option value="">{t("editProductModal.selectUnit")}</option>
                  {units.map((unit) => (
                    <option key={unit.unit_id} value={unit.unit_id}>
                      {unit.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="d-flex w-100 justify-content-between mt-3">
                <button
                  type="submit"
                  className="btn btn-success w-25 me-4 form-control"
                >
                  <FaSave />
                  <span className="ms-2">
                    {t("editProductModal.saveButton")}
                  </span>
                </button>
                <button
                  type="button"
                  className="btn btn-danger w-25"
                  onClick={onClose}
                >
                  <ImCross />
                  <span className="ms-2">
                    {t("editProductModal.cancelButton")}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProductForm;
