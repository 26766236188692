import React, { useState, useEffect } from "react";
import axios from "axios";
import VatRateForm from "./VATForm";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash } from "react-icons/fa";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

function VatRates() {
  const [vatRates, setVatRates] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVatRate, setCurrentVatRate] = useState({ name: "", rate: "" });
  const { t } = useTranslation();

  useEffect(() => {
    fetchVatRates();
  }, []);

  const fetchVatRates = async () => {
    try {
      const response = await axios.get("/setup/vat-rates");
      setVatRates(response.data);
    } catch (error) {
      console.error(t("vatRates.fetchError"), error);
    }
  };

  const handleAddOrUpdateVatRate = async (vatRateData) => {
    const method = vatRateData.vat_id ? "put" : "post";
    const url = vatRateData.vat_id
      ? `/setup/vat-rates/${vatRateData.vat_id}`
      : "/setup/vat-rates";
    try {
      const response = await axios[method](url, vatRateData);
      console.log(
        vatRateData.vat_id ? "VAT rate updated" : "VAT rate added",
        response.data
      );
      fetchVatRates();
    } catch (error) {
      console.error(
        "Error updating/adding VAT rate:",
        error.response ? error.response.data : error
      );
      alert(
        `Error updating VAT rate: ${
          error.response
            ? JSON.stringify(error.response.data)
            : "Server not responding"
        }`
      );
    }
    closeModal();
  };

  const handleDeleteVatRate = async (vatId) => {
    try {
      await axios.delete(`/setup/vat-rates/${vatId}`);
      fetchVatRates();
    } catch (error) {
      console.error(t("vatRates.deleteError"), error);
    }
  };

  const openModalToAdd = () => {
    setCurrentVatRate({ name: "", rate: "" });
    setModalIsOpen(true);
  };

  const openModalToEdit = (vatRate) => {
    setCurrentVatRate(vatRate);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVatRate({ name: "", rate: "" });
  };

  return (
    <div className="container-fluid ">
      <h2>{t("vatRates.title")}</h2>
      <button
        className="btn btn-primary my-3 w-29-5 text-center"
        onClick={openModalToAdd}
      >
        {t("vatRates.addNew")}
      </button>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("vatRates.name")}</th>
              <th>{t("vatRates.rate")}</th>
              <th>{t("vatRates.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {vatRates.map((vatRate) => (
              <tr key={vatRate.vat_id}>
                <td>{vatRate.name}</td>
                <td>{vatRate.rate}</td>
                <td className="d-flex ">
                  <button
                    className="btn btn-success m-1"
                    onClick={() => openModalToEdit(vatRate)}
                  >
                    <FaEdit />
                    <span className="text-icon">{t("vatRates.edit")}</span>
                  </button>
                  <button
                    className="btn btn-danger m-1"
                    onClick={() => handleDeleteVatRate(vatRate.vat_id)}
                  >
                    <FaTrash />
                    <span className="text-icon">{t("vatRates.delete")}</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalIsOpen && (
        <VatRateForm
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSave={handleAddOrUpdateVatRate}
          initialData={currentVatRate}
        />
      )}
    </div>
  );
}

export default VatRates;
