import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useTranslation } from "react-i18next";

function ProductDetailsOffcanvas({ isOpen, onClose, product }) {
  const { t } = useTranslation();

  if (!product) return null;

  return (
    <Offcanvas show={isOpen} onHide={onClose} placement="end" className="w-50">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{t("products.detailsTitle")}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <table className="table table-bordered table-striped">
          <tbody>
            {/* <tr>
              <th>{t("products.creator")}</th>
              <td>{product.registration_src}</td>
            </tr> */}
            <tr>
              <th>{t("products.mainCategory")}</th>
              <td>{product.main_category_name}</td>
            </tr>
            <tr>
              <th>{t("products.childCategory")}</th>
              <td>{product.child_category_name}</td>
            </tr>
            <tr>
              <th>{t("products.supplier")}</th>
              <td>{product.supplier_name}</td>
            </tr>
            <tr>
              <th>{t("products.warehouse")}</th>
              <td>{product.warehouse_name}</td>
            </tr>
            <tr>
              <th>{t("products.billNumber")}</th>
              <td>{product.bill_number}</td>
            </tr>
            <tr>
              <th>{t("products.purchasePrice")}</th>
              <td>{product.purchase_price}</td>
            </tr>
            <tr>
              <th>{t("products.stockQuantity")}</th>
              <td>{product.stock_quantity}</td>
            </tr>
            <tr>
              <th>{t("products.lotNumber")}</th>
              <td>{product.lot_number}</td>
            </tr>
            <tr>
              <th>{t("products.arrivalDate")}</th>
              <td>{new Date(product.arrival_date).toLocaleDateString()}</td>
            </tr>
            <tr>
              <th>{t("products.expirationDate")}</th>
              <td>{new Date(product.expiration_date).toLocaleDateString()}</td>
            </tr>
            <tr>
              <th>{t("products.unit")}</th>
              <td>{product.unit}</td>
            </tr>
            <tr>
              <th>{t("products.creator")}</th>
              <td>{product.registration_src}</td>
            </tr>
            <tr>
              <th>{t("products.vatRate")}</th>
              <td>{product.vat_rate}%</td>
            </tr>
            <tr>
              <th>{t("products.totalPrice")}</th>
              <td>
                {product.total_price
                  ? Number(product.total_price).toFixed(2)
                  : "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default ProductDetailsOffcanvas;
