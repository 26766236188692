import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSave, FaTrash, FaPlus } from "react-icons/fa";
import { ImCross } from "react-icons/im";
function EditOrderModal({
  isOpen,
  onClose,
  onSave,
  initialData,
  customers,
  products,
  paymentMethods,
  vatRates,
}) {
  const [formData, setFormData] = useState({
    customer_id: "",
    bill_number: "",
    order_date: "",
    status: "",
    payment_status: "",
    bucket_count: "",
    bucket_cost: "",
    hasBucket: false,
    order_items: [{ product_id: "", quantity: "", sell_price: "", vat_id: "" }],
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setFormData({
        customer_id: initialData?.customer_id || "",
        bill_number: initialData?.bill_number || "",
        order_date: initialData?.order_date || "",
        status: initialData?.status || "",
        payment_status: initialData?.payment_status || "",
        order_items: initialData?.items || [
          { product_id: "", quantity: "", sell_price: "", vat_id: "" },
        ],
        bucket_count: initialData?.bucket_count || "",
        bucket_cost: initialData?.bucket_cost || "",
        hasBucket: initialData?.has_bucket == 1 ? true : false,
      });
    }
  }, [initialData, isOpen]);
  const handleBucketChange = (e) => {
    const { checked } = e.target;
    setFormData({ ...formData, hasBucket: checked ? true : false });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOrderItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedOrderItems = formData.order_items.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormData((prev) => ({ ...prev, order_items: updatedOrderItems }));
  };

  const handleAddOrderItem = () => {
    setFormData((prev) => ({
      ...prev,
      order_items: [
        ...prev.order_items,
        { product_id: "", quantity: "", sell_price: "" },
      ],
    }));
  };

  const handleRemoveOrderItem = (index) => {
    setFormData((prev) => ({
      ...prev,
      order_items: prev.order_items.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          {initialData.customer_id
            ? t("editOrderModal.editTitle")
            : t("editOrderModal.addTitle")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} id="form-100">
          <Form.Group>
            <Form.Label>{t("editOrderModal.customer")}:</Form.Label>
            <Form.Control
              as="select"
              name="customer_id"
              value={formData.customer_id}
              onChange={handleChange}
              required
            >
              <option value="">{t("editOrderModal.selectCustomer")}</option>
              {customers.map((customer) => (
                <option key={customer.customer_id} value={customer.customer_id}>
                  {customer.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>{t("editOrderModal.billNumber")}:</Form.Label>
            <Form.Control
              type="text"
              name="bill_number"
              value={formData?.bill_number}
              onChange={handleChange}
              className="w-100"
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>{t("editOrderModal.date")}:</Form.Label>
            <Form.Control
              type="date"
              name="order_date"
              value={formData.order_date}
              className="w-100"
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>{t("editOrderModal.status")}:</Form.Label>
            <Form.Control
              as="select"
              name="status"
              value={formData.status}
              onChange={handleChange}
              required
            >
              <option value="">{t("editOrderModal.selectStatus")}</option>
              <option value="pending">{t("orderStatus.pending")}</option>
              <option value="completed">{t("orderStatus.completed")}</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("editOrderModal.paymentStatus")}:</Form.Label>
            <Form.Control
              as="select"
              name="payment_status"
              value={formData.payment_status}
              onChange={handleChange}
              required
            >
              <option value="">
                {t("editOrderModal.selectPaymentStatus")}
              </option>
              {paymentMethods.map((method) => (
                <option key={method.method_id} value={method.method_id}>
                  {method.method_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Row className="mb-3 align-items-center">
            <Col md={12}>
              <Form.Check
                type="checkbox"
                label={t("editOrderModal.includeBucket")}
                onChange={handleBucketChange}
                className="mb-2"
              />
            </Col>
            {formData.hasBucket && (
              <>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>{t("editOrderModal.bucketCount")}:</Form.Label>
                    <Form.Control
                      type="number"
                      name="bucket_count"
                      value={formData.bucket_count}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>{t("editOrderModal.bucketCost")}:</Form.Label>
                    <Form.Control
                      type="number"
                      name="bucket_cost"
                      value={formData.bucket_cost}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </>
            )}
          </Row>
          {!initialData.customer_id &&
            formData.order_items.map((item, index) => (
              <div key={index} className="mb-3 bg-ccc">
                <Row className="align-items-center">
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>{t("editOrderModal.product")}:</Form.Label>
                      <Form.Control
                        as="select"
                        name="product_id"
                        value={item.product_id}
                        onChange={(e) => handleOrderItemChange(index, e)}
                        required
                        className="select-input"
                      >
                        <option value="">
                          {t("editOrderModal.selectProduct")}
                        </option>
                        {products.map((product) => (
                          <option
                            key={product.product_id}
                            value={product.product_id}
                          >
                            {product.lot_number} - {product.unit} -{" "}
                            {product.child_category_name} (
                            {product.main_category_name}) -{" "}
                            {product.warehouse_name} - {product.stock_quantity}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>{t("editOrderModal.quantity")}:</Form.Label>
                      <Form.Control
                        type="number"
                        name="quantity"
                        value={item.quantity}
                        onChange={(e) => handleOrderItemChange(index, e)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>{t("editOrderModal.price")}:</Form.Label>
                      <Form.Control
                        type="number"
                        name="sell_price"
                        value={item.sell_price}
                        onChange={(e) => handleOrderItemChange(index, e)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>{t("editOrderModal.vatRate")}:</Form.Label>
                      <Form.Control
                        as="select"
                        name="vat_id"
                        value={item.vat_id}
                        onChange={(e) => handleOrderItemChange(index, e)}
                        className="select-input"
                        required
                      >
                        <option value="">
                          {t("editOrderModal.selectVatRate")}
                        </option>
                        {vatRates.map((rate) => (
                          <option key={rate.vat_id} value={rate.vat_id}>
                            {rate.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col md={12} className="d-flex align-items-end">
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveOrderItem(index)}
                    >
                      <FaTrash />
                      <span className="text-icon ms-2 ">
                        {t("editOrderModal.removeItem")}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
        </Form>
      </Modal.Body>
      <div className="row mb-4 ms-3 me-3">
        <div className="col-6">
          <Button variant="success" onClick={handleSubmit} className="w-100 ">
            <FaSave />
            <span className="text-icon ms-1">
              {t("editOrderModal.saveButton")}
            </span>
          </Button>
        </div>
        {!initialData.customer_id && (
          <div className="col-3">
            <Button
              variant="primary"
              onClick={handleAddOrderItem}
              className="w-100 "
            >
              <FaPlus />
              <span className="text-icon ms-1">
                {t("editOrderModal.addItem")}
              </span>
            </Button>
          </div>
        )}
        <div className="col-3">
          <Button variant="danger" onClick={onClose} className="w-100 ">
            <FaTrash />
            <span className="text-icon ms-1">
              {t("editOrderModal.cancelButton")}
            </span>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EditOrderModal;
