import React, { useState, useEffect } from "react";
import axios from "axios";
import EditSupplierModal from "./EditSupplierModal";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash } from "react-icons/fa";

function Suppliers() {
  const [suppliers, setSuppliers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentSupplier, setCurrentSupplier] = useState(null);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const { t } = useTranslation();

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const fetchSuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/suppliers`
      );
      setSuppliers(response.data);
      setError("");
    } catch (error) {
      console.error("Failed to fetch suppliers:", error);
      setError(t("errors.fetchFailed")); // Translate error message
      setSuppliers([]); // Ensure suppliers is an array even if the fetch fails
    }
  };

  const handleAddOrUpdateSupplier = async (supplierData) => {
    const apiEndpoint = currentSupplier
      ? `${process.env.REACT_APP_API_URL}/suppliers/${currentSupplier.supplier_id}`
      : `${process.env.REACT_APP_API_URL}/suppliers`;
    try {
      const method = currentSupplier ? "put" : "post";
      await axios[method](apiEndpoint, supplierData);
      fetchSuppliers();
      closeModal();
    } catch (error) {
      console.error("Failed to add or update supplier:", error);
      setError(t("errors.updateFailed"));
    }
  };

  const handleDeleteSupplier = async (supplierId) => {
    const confirmDelete = window.confirm(t("confirmDelete"));
    if (!confirmDelete) return;

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/suppliers/${supplierId}`
      );
      fetchSuppliers();
    } catch (error) {
      console.error("Failed to delete supplier:", error);
      setError(t("errors.deleteFailed"));
    }
  };

  const openModalToAdd = () => {
    setCurrentSupplier(null);
    setModalIsOpen(true);
  };

  const openModalToEdit = (supplier) => {
    setCurrentSupplier(supplier);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentSupplier(null);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredSuppliers = suppliers.filter((supplier) => {
    return (
      supplier.name.toLowerCase().includes(searchQuery) ||
      supplier.address.toLowerCase().includes(searchQuery) ||
      supplier.email.toLowerCase().includes(searchQuery) ||
      supplier.phone.toLowerCase().includes(searchQuery) ||
      supplier.contact_person.toLowerCase().includes(searchQuery) ||
      supplier.contact_email.toLowerCase().includes(searchQuery) ||
      supplier.bank_account.toLowerCase().includes(searchQuery) ||
      supplier.payment_method.toLowerCase().includes(searchQuery) ||
      supplier.currency.toLowerCase().includes(searchQuery) ||
      supplier.vat_number.toString().includes(searchQuery)
    );
  });

  return (
    <div className="container-fluid mt-4">
      <h2>{t("suppliers.title")}</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <diV className="d-flex justify-content-between">
        <button
          className="btn btn-primary my-3 w-29-5 text-center"
          onClick={openModalToAdd}
        >
          {t("suppliers.addNew")}
        </button>
        <input
          type="text"
          className="form-control mb-3 w-25 h-75"
          placeholder={t("suppliers.search")}
          value={searchQuery}
          onChange={handleSearch}
        />
      </diV>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("suppliers.name")}</th>
              <th>{t("suppliers.address")}</th>
              <th>{t("suppliers.email")}</th>
              <th>{t("suppliers.phone")}</th>
              <th>{t("suppliers.contactPerson")}</th>
              <th>{t("suppliers.contactEmail")}</th>
              <th>{t("suppliers.bankAccount")}</th>
              <th>{t("suppliers.paymentMethod")}</th>
              <th>{t("suppliers.currency")}</th>
              <th>{t("suppliers.vatNumber")}</th>
              <th>{t("suppliers.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(filteredSuppliers) &&
              filteredSuppliers.map((supplier) => (
                <tr key={supplier.supplier_id}>
                  <td>{supplier.name}</td>
                  <td>{supplier.address}</td>
                  <td>{supplier.email}</td>
                  <td>{supplier.phone}</td>
                  <td>{supplier.contact_person}</td>
                  <td>{supplier.contact_email}</td>
                  <td>{supplier.bank_account}</td>
                  <td>{supplier.payment_method}</td>
                  <td>{supplier.currency}</td>
                  <td>{supplier.vat_number}</td>
                  <td className="d-flex ">
                    <button
                      className="btn btn-success m-1 d-flex align-items-center"
                      onClick={() => openModalToEdit(supplier)}
                    >
                      <FaEdit />
                      <span className="text-icon"> {t("suppliers.edit")}</span>
                    </button>
                    <button
                      className="btn btn-danger m-1 d-flex align-items-center"
                      onClick={() => handleDeleteSupplier(supplier.supplier_id)}
                    >
                      <FaTrash />
                      <span className="text-icon">{t("suppliers.delete")}</span>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {modalIsOpen && (
        <EditSupplierModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSave={handleAddOrUpdateSupplier}
          initialData={currentSupplier || {}}
        />
      )}
    </div>
  );
}

export default Suppliers;
