import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaSave } from "react-icons/fa";
import { ImCross } from "react-icons/im";
function UnitForm({ isOpen, onClose, onSave, initialData }) {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setFormData({
        name: initialData?.name || "",
        description: initialData?.description || "",
        unit_id: initialData?.unit_id || null,
      });
    }
  }, [initialData, isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      unit_id: formData.unit_id,
      name: formData.name,
      description: formData.description,
    });
    onClose();
  };

  return (
    <div
      className={`modal modal-xl fade ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {formData.unit_id
                ? t("unitForm.editTitle")
                : t("unitForm.addTitle")}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} id="form-100">
              <div className="form-group">
                <label>{t("unitForm.name")}:</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>{t("unitForm.description")}:</label>
                <textarea
                  className="form-control"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className=" d-flex w-100 justify-content-between mt-3">
                <button
                  type="submit"
                  className="btn btn-success w-25 me-4 form-control"
                >
                  <FaSave />
                  <span className="text-icon">
                    {t("categoryForm.saveButton")}
                  </span>
                </button>

                <button
                  type="button"
                  className="btn btn-danger w-25"
                  onClick={onClose}
                >
                  <ImCross />

                  <span className="text-icon">
                    {t("categoryForm.cancelButton")}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnitForm;
