import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaSave } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const CURRENCY_CODES = [
  "EUR",
  "GBP",
  "CHF",
  "NOK",
  "SEK",
  "DKK",
  "PLN",
  "CZK",
  "HUF",
  "RUB",
  "UAH",
  "BGN",
  "RON",
  "HRK",
  "ISK",
  "TRY",
  "USD",
  "JPY",
  "AUD",
  "CAD",
  "CNY",
  "INR",
  "SGD",
  "NZD",
];

function CurrencyForm({ isOpen, onClose, onSave, initialData }) {
  const [formData, setFormData] = useState({
    currency_code: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setFormData({
        currency_code: initialData?.currency_code || "",
        currency_id: initialData?.currency_id || null,
      });
    }
  }, [initialData, isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      currency_id: formData.currency_id,
      currency_code: formData.currency_code,
    });
    onClose();
  };

  return (
    <div
      className={`modal modal-xl fade ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {formData.currency_id
                ? t("currencyForm.editTitle")
                : t("currencyForm.addTitle")}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} id="form-100">
              <div className="form-group">
                <label>{t("currencyForm.currencyCode")}:</label>
                <select
                  name="currency_code"
                  className="form-control"
                  value={formData.currency_code}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">{t("currencyForm.selectCurrency")}</option>
                  {CURRENCY_CODES.map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex w-100 justify-content-between mt-3">
                <button
                  type="submit"
                  className="btn btn-success w-25 me-4 form-control"
                >
                  <FaSave />
                  <span className="text-icon">
                    {t("currencyForm.saveButton")}
                  </span>
                </button>

                <button
                  type="button"
                  className="btn btn-danger w-25"
                  onClick={onClose}
                >
                  <ImCross />
                  <span className="text-icon">
                    {t("currencyForm.cancelButton")}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CurrencyForm;
