import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash, FaSave, FaPlus } from "react-icons/fa";
import { ImCross } from "react-icons/im";
function RecipeManagement({ recipes, fetchRecipes, setErrorMessage }) {
  const { t } = useTranslation();
  const [newRecipe, setNewRecipe] = useState({
    name: "",
    description: "",
    lostPercentage: 0,
    product_id: "",
    cuts: [],
  });
  const [editingRecipe, setEditingRecipe] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("/products");
        setProducts(response.data);
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };

    fetchProducts();
  }, []);
  const removeCut = (src, setSrc, index) => {
    setSrc({ ...src, cuts: src.cuts.filter((cut, i) => i !== index) });
  };
  const addRecipe = async () => {
    const totalPercentage = newRecipe.cuts.reduce((acc, cut) => {
      return acc + parseFloat(cut.percentage);
    }, +newRecipe.lostPercentage);
    if (
      !newRecipe.name ||
      !newRecipe.description ||
      newRecipe.cuts.length === 0
    ) {
      setErrorMessage(t("recipes.nameRequired"));
      return;
    }

    if (!newRecipe.product_id) {
      setErrorMessage(t("recipes.mainPieceRequired"));
      return;
    }
    for (const cut of newRecipe.cuts) {
      if (!cut.product_id || cut.percentage === undefined) {
        setErrorMessage(t("recipes.cutRequired"));
        return;
      }
    }

    if (totalPercentage !== 100) {
      setErrorMessage(t("recipes.totalPercentageError"));
      return;
    }
    try {
      await axios.post("/recipes", newRecipe);
      fetchRecipes();
      console.log("--------- fetched recipes ", recipes);
      setNewRecipe({ name: "", description: "", lostPercentage: "", cuts: [] });
      setErrorMessage("");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        console.error(t("recipes.errorAdding"), error);
      }
    }
  };

  const updateRecipe = async (id) => {
    console.log("editing cut -------------------------");
    console.log(editingRecipe);
    const totalPercentage = editingRecipe.cuts.reduce((acc, cut) => {
      return acc + parseFloat(cut.percentage);
    }, +editingRecipe.lostPercentage);

    if (totalPercentage != 100) {
      setErrorMessage(t("recipes.totalPercentageError"));
      return;
    }

    try {
      await axios.put(`/recipes/${id}`, editingRecipe);
      fetchRecipes();
      setEditingRecipe(null);
      setErrorMessage("");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        console.error(t("recipes.errorUpdating"), error);
      }
    }
  };

  const deleteRecipe = async (id) => {
    try {
      await axios.delete(`/recipes/${id}`);
      fetchRecipes();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        console.error(t("recipes.errorDeleting"), error);
      }
    }
  };

  const handleMainPieceChange = (field, value) => {
    setNewRecipe({ ...newRecipe, [field]: value });
  };

  const handleCutChange = (src, setSrc, cutIndex, field, value) => {
    const updatedCuts = src.cuts.map((cut, cIdx) =>
      cIdx === cutIndex ? { ...cut, [field]: value } : cut
    );
    setSrc({ ...src, cuts: updatedCuts });
  };

  const addCutField = (src, setSrc) => {
    setSrc({
      ...src,
      cuts: [...src.cuts, { product_id: "", percentage: "" }],
    });
  };

  const cancelEditing = () => {
    setEditingRecipe(null);
    setErrorMessage("");
  };

  const cancelAdding = () => {
    setNewRecipe({
      name: "",
      description: "",
      lostPercentage: 0,
      product_id: "",
      cuts: [],
    });
    setErrorMessage("");
  };

  return (
    <div className="card mb-4">
      <div className="card-header">
        <h2>{t("recipes.title")}</h2>
      </div>
      <div className="card-body">
        <div className="mb-3">
          <input
            type="text"
            className="form-control mb-2 w-100"
            placeholder={t("recipes.name")}
            value={newRecipe.name}
            onChange={(e) =>
              setNewRecipe({ ...newRecipe, name: e.target.value })
            }
            required
          />
          <textarea
            className="form-control mb-2"
            placeholder={t("recipes.description")}
            value={newRecipe.description}
            onChange={(e) =>
              setNewRecipe({ ...newRecipe, description: e.target.value })
            }
            required
          />
          <div className="mb-2">
            {
              <div className="mb-2">
                <select
                  className="form-control mb-2"
                  value={newRecipe.product_id}
                  onChange={(e) =>
                    handleMainPieceChange("product_id", e.target.value)
                  }
                  required
                >
                  <option value="">{t("recipes.selectProduct")}</option>
                  {products.map((product) => (
                    <option key={product.product_id} value={product.product_id}>
                      {product.main_category_name} -{" "}
                      {product.child_category_name}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  className="form-control mb-2 w-100"
                  placeholder="lost percentage"
                  onChange={(e) =>
                    handleMainPieceChange("lostPercentage", e.target.value)
                  }
                  min="0"
                  max="5"
                  required
                />
                {newRecipe.cuts.map((cut, cutIndex) => (
                  <div
                    key={cutIndex}
                    className="d-flex mt-4 mb-4 pt-2 pb-2 bg-ccc"
                  >
                    <select
                      className="form-control m-2"
                      value={cut.product_id}
                      onChange={(e) =>
                        handleCutChange(
                          newRecipe,
                          setNewRecipe,
                          cutIndex,
                          "product_id",
                          e.target.value
                        )
                      }
                      required
                    >
                      <option value="">{t("recipes.selectCutProduct")}</option>
                      {products.map((product) => (
                        <option
                          key={product.product_id}
                          value={product.product_id}
                        >
                          {product.child_category_name} -{" "}
                          {product.main_category_name}
                        </option>
                      ))}
                    </select>
                    <input
                      type="number"
                      className="form-control m-2"
                      placeholder={t("recipes.cutPercentage")}
                      value={cut.percentage}
                      onChange={(e) =>
                        handleCutChange(
                          newRecipe,
                          setNewRecipe,
                          cutIndex,
                          "percentage",
                          e.target.value
                        )
                      }
                      min="0"
                      max="100"
                      required
                    />
                    <button
                      className="btn btn-danger m-2"
                      onClick={() =>
                        removeCut(newRecipe, setNewRecipe, cutIndex)
                      }
                    >
                      <FaTrash />
                      <span className="text-icon ms-2">remove cut</span>
                    </button>
                  </div>
                ))}
              </div>
            }
          </div>
          <div className="d-flex ">
            <button
              className="btn btn-primary m-2"
              onClick={() => addCutField(newRecipe, setNewRecipe)}
            >
              <FaPlus />

              <span className="text-icon ms-2"> {t("recipes.addCut")}</span>
            </button>
            <button className="btn btn-success m-2" onClick={addRecipe}>
              <FaSave />
              <span className="text-icon ms-2">{t("recipes.add")}</span>
            </button>
            <button className="btn btn-danger m-2" onClick={cancelAdding}>
              <ImCross />
              <span className="text-icon ms-2">{t("actions.cancel")}</span>
            </button>
          </div>
        </div>
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("recipes.name")}</th>
              <th>{t("recipes.description")}</th>
              <th>{t("actions.edit")}</th>
              <th>{t("actions.delete")}</th>
            </tr>
          </thead>
          <tbody>
            {recipes.map((recipe) => (
              <tr key={recipe.recipe_id}>
                <td>{recipe.name}</td>
                <td>{recipe.description}</td>
                <td className="d-flex">
                  <button
                    className="btn btn-success m-1"
                    onClick={() => setEditingRecipe(recipe)}
                  >
                    <FaEdit />
                    <span className="text-icon ms-2">{t("actions.edit")}</span>
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-danger m-1"
                    onClick={() => deleteRecipe(recipe.recipe_id)}
                  >
                    <FaTrash />
                    <span className="text-icon ms-2">
                      {t("actions.delete")}
                    </span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {editingRecipe && (
          <div>
            <input
              type="text"
              className="form-control mb-2 w-100"
              value={editingRecipe.name}
              onChange={(e) =>
                setEditingRecipe({ ...editingRecipe, name: e.target.value })
              }
              required
            />
            <textarea
              className="form-control mb-2 w-100"
              value={editingRecipe.description}
              onChange={(e) =>
                setEditingRecipe({
                  ...editingRecipe,
                  description: e.target.value,
                })
              }
              required
            />
            <input
              type="text"
              className="form-control mb-2 w-100"
              value={editingRecipe.lostPercentage}
              onChange={(e) =>
                setEditingRecipe({
                  ...editingRecipe,
                  lostPercentage: e.target.value,
                })
              }
              required
            />
            <div className="mb-2">
              <div k className="mb-2">
                <select
                  className="form-control mb-2 w-100"
                  value={editingRecipe.product_id}
                  onChange={(e) =>
                    handleMainPieceChange("product_id", e.target.value)
                  }
                  required
                >
                  <option value="">{t("recipes.selectProduct")}</option>
                  {products.map((product) => (
                    <option key={product.product_id} value={product.product_id}>
                      {product.child_category_name} -{" "}
                      {product.main_category_name}
                    </option>
                  ))}
                </select>
                {editingRecipe.cuts.map((cut, cutIndex) => (
                  <div key={cutIndex} className="mb-2">
                    <select
                      className="form-control mb-2"
                      value={cut.product_id}
                      onChange={(e) =>
                        handleCutChange(
                          editingRecipe,
                          setEditingRecipe,
                          cutIndex,
                          "product_id",
                          e.target.value
                        )
                      }
                      required
                    >
                      <option value="">{t("recipes.selectCutProduct")}</option>
                      {products.map((product) => (
                        <option
                          key={product.product_id}
                          value={product.product_id}
                        >
                          {product.child_category_name} -{" "}
                          {product.main_category_name}
                        </option>
                      ))}
                    </select>
                    <input
                      type="number"
                      className="form-control mb-2 w-100"
                      value={cut.percentage}
                      onChange={(e) =>
                        handleCutChange(
                          editingRecipe,
                          setEditingRecipe,
                          cutIndex,
                          "percentage",
                          e.target.value
                        )
                      }
                      min="0"
                      max="100"
                      required
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-primary m-2"
                onClick={() => addCutField(editingRecipe, setEditingRecipe)}
              >
                <FaPlus />
                <span className="text-icon ms-2">{t("recipes.addCut")}</span>
              </button>
              <button
                className="btn btn-success m-2"
                onClick={() => updateRecipe(editingRecipe.recipe_id)}
              >
                <FaEdit />

                <span className="text-icon ms-2">{t("recipes.update")}</span>
              </button>
              <button className="btn btn-danger m-2" onClick={cancelEditing}>
                <ImCross />

                <span className="text-icon ms-2">{t("actions.cancel")}</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RecipeManagement;
