import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  useMatch,
} from "react-router-dom";
import { useAuth } from "./components/AuthProvider";
import Register from "./components/Register";
import Login from "./components/Login";
import Products from "./components/Products/Products";
import Customers from "./components/Customers/Customers";
import Orders from "./components/Orders/Orders";
import Suppliers from "./components/Suppliers/Suppliers";
import UserProfile from "./components/UserProfile";
import Setup from "./components/Setup/Setup";
import Inventory from "./components/Inventory";
import MainComponent from "./components/MainComponent";
import Expenses from "./components/Expenses";
import ExpensesSummary from "./components/ExpensesSummary";
import DeliveryNotes from "./components/DeliveryNotes";
import LanguageSwitcher from "./i18n/LanguageSwitcher";
import { useTranslation } from "react-i18next";

import "./i18n/i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import NavBar from "./NavBar";

function App() {
  const { token, logout } = useAuth();
  const { t } = useTranslation();

  return (
    <Router>
      <div>
        <Navbar bg="light" expand="lg">
          <NavBar />
        </Navbar>
        <Routes>
          <Route
            path="/login"
            element={!token ? <Login /> : <Navigate replace to="/setup" />}
          />
          <Route
            path="/register"
            element={!token ? <Register /> : <Navigate replace to="/setup" />}
          />
          {token && (
            <>
              <Route path="/" element={<Navigate replace to="/setup" />} />
              <Route path="/setup" element={<Setup />} />
              <Route path="/suppliers" element={<Suppliers />} />
              <Route path="/products" element={<Products />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/lab" element={<MainComponent />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/expenses" element={<Expenses />} />
              <Route path="/expenses-summary" element={<ExpensesSummary />} />
              <Route path="/delivery-notes" element={<DeliveryNotes />} />{" "}
            </>
          )}
          <Route
            path="*"
            element={<Navigate to={token ? "/setup" : "/login"} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
