import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FaPlus, FaSave, FaTrash } from "react-icons/fa";
import { ImCross } from "react-icons/im";

function CategoryForm({ isOpen, onClose, onSave, initialData }) {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    children: [{ name: "", vat_rate_id: "" }],
  });
  const [vatRates, setVatRates] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setFormData({
        name: initialData.name || "",
        description: initialData.description || "",
        children:
          initialData.children && initialData.children.length > 0
            ? initialData.children.map((child) => ({
                name: child.name,
                vat_rate_id: child.vat_rate_id,
              }))
            : [{ name: "", vat_rate_id: "" }],
        category_id: initialData.category_id || null,
      });
      fetchVatRates();
    }
  }, [initialData, isOpen]);

  const fetchVatRates = async () => {
    try {
      const response = await axios.get("/setup/vat-rates");
      setVatRates(response.data);
    } catch (error) {
      console.error("Failed to fetch VAT rates:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChildChange = (index, e) => {
    const { name, value } = e.target;
    const newChildren = [...formData.children];
    newChildren[index][name] = value;
    setFormData({ ...formData, children: newChildren });
  };

  const handleAddChildCategory = () => {
    setFormData((prev) => ({
      ...prev,
      children: [...prev.children, { name: "", vat_rate_id: "" }],
    }));
  };

  const handleRemoveChildCategory = (index) => {
    const newChildren = [...formData.children];
    if (newChildren.length > 1) {
      newChildren.splice(index, 1);
    } else {
      alert(t("categoryForm.atLeastOneChild"));
    }
    setFormData({ ...formData, children: newChildren });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      category_id: formData.category_id,
      name: formData.name,
      description: formData.description,
      children: formData.children,
    });
    onClose();
  };

  return (
    <div
      className={`modal modal-xl fade ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      tabIndex="-1"
      aria-labelledby="categoryFormModal"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="categoryFormModal">
              {initialData.category_id
                ? t("categoryForm.editTitle")
                : t("categoryForm.addTitle")}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} className="w-100" id="form-100">
              <div className="d-flex w-100 justify-content-between">
                <div className="mb-3 w-100">
                  <label className="form-label">
                    {t("categoryForm.name")}:
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3 w-100">
                  <label className="form-label">
                    {t("categoryForm.description")}:
                  </label>
                  <input
                    type="text"
                    name="description"
                    className="form-control"
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              {formData.children.map((child, index) => (
                <div
                  key={index}
                  className="mb-3 d-flex w-100 justify-content-between"
                >
                  <div className="w-100">
                    <input
                      type="text"
                      name="name"
                      placeholder={`${t("categoryForm.childName")} ${
                        index + 1
                      } . . .`}
                      className="form-control"
                      value={child.name}
                      onChange={(e) => handleChildChange(index, e)}
                      required
                    />
                  </div>
                  <div className="w-100 d-flex justify-content-between">
                    <div className="w-100 me-2">
                      <select
                        name="vat_rate_id"
                        className="form-select"
                        value={child.vat_rate_id}
                        onChange={(e) => handleChildChange(index, e)}
                        required
                      >
                        <option value="">
                          {t("categoryForm.selectVatRate")}
                        </option>
                        {vatRates.map((vatRate) => (
                          <option key={vatRate.vat_id} value={vatRate.vat_id}>
                            {vatRate.name} - {vatRate.rate}%
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-100">
                      <button
                        type="button"
                        className="btn btn-danger "
                        onClick={() => handleRemoveChildCategory(index)}
                      >
                        <FaTrash />
                        <span className="text-icon">
                          {t("categoryForm.removeChild")}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}

              <div className=" d-flex w-100 justify-content-between">
                <button
                  type="submit"
                  className="btn btn-success w-50 me-4 form-control"
                >
                  <FaSave />
                  <span className="text-icon">
                    {t("categoryForm.saveButton")}
                  </span>
                </button>

                <button
                  type="button"
                  className="btn btn-primary  me-4 w-25"
                  onClick={handleAddChildCategory}
                >
                  <FaPlus />
                  <span className="text-icon">
                    {t("categoryForm.addChild")}
                  </span>
                </button>
                <button
                  type="button"
                  className="btn btn-danger w-25"
                  onClick={onClose}
                >
                  <ImCross />

                  <span className="text-icon">
                    {t("categoryForm.cancelButton")}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryForm;
