import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import RecipeManagement from "./RecipeManagement";
import LabManagement from "./LabManagement";

function MainComponent() {
  const { t } = useTranslation();
  const [recipes, setRecipes] = useState([]);
  const [labProcesses, setLabProcesses] = useState([]);
  const [products, setProducts] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [inventory, setInventory] = useState([]);

  useEffect(() => {
    fetchRecipes();
    fetchLabProcesses();
    fetchProducts();
    fetchInventory();
  }, []);

  const fetchRecipes = async () => {
    try {
      const response = await axios.get("/recipes");
      setRecipes(response.data);
    } catch (error) {
      console.error(t("errors.fetchFailed"), error);
    }
  };

  const fetchLabProcesses = async () => {
    try {
      const response = await axios.get("/lab");
      setLabProcesses(response.data);
    } catch (error) {
      console.error(t("errors.fetchFailed"), error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get("/products");
      setProducts(response.data);
    } catch (error) {
      console.error(t("errors.fetchFailed"), error);
    }
  };
  const fetchInventory = async () => {
    try {
      const response = await axios.get("/inventory/select");
      setInventory(response.data);
    } catch (error) {
      console.error(t("errors.fetchFailed"), error);
    }
  };
  return (
    <div className="container mt-4 ">
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      <RecipeManagement
        recipes={recipes}
        fetchRecipes={fetchRecipes}
        setErrorMessage={setErrorMessage}
      />
      <LabManagement
        recipes={recipes}
        labProcesses={labProcesses}
        fetchInventory={fetchInventory}
        inventory={inventory}
        products={products}
        fetchLabProcesses={fetchLabProcesses}
        fetchProducts={fetchProducts}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
}

export default MainComponent;
