import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Products from "./Products-inventory/Products";
function Inventory() {
  const { t } = useTranslation();
  const [inventories, setInventories] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [summary, setSummary] = useState([]);

  useEffect(() => {
    fetchInventories();
    fetchAlerts();
  }, []);

  const fetchInventories = async () => {
    try {
      const response = await axios.get("/inventory/statistics");
      const data = response.data;

      const summaryData = data.reduce((acc, curr) => {
        const key = `${curr.main_category_name}-${curr.child_category_name}-${curr.warehouse_name}`;
        if (!acc[key]) {
          acc[key] = {
            main_category_name: curr.main_category_name,
            child_category_name: curr.child_category_name,
            warehouse_name: curr.warehouse_name,
            quantity: 0,
          };
        }
        acc[key].quantity += Number(curr.quantity); // Ensure the quantity is treated as a number
        return acc;
      }, {});

      // Separate inventories with quantity 0 and others
      const nonZeroInventories = data.filter((item) => item.quantity !== 0);
      const zeroInventories = data.filter((item) => item.quantity === 0);

      // Sort non-zero inventories by expiration date
      nonZeroInventories.sort(
        (a, b) => new Date(a.expiration_date) - new Date(b.expiration_date)
      );

      // Combine them with zero inventories at the end
      const sortedData = [...nonZeroInventories, ...zeroInventories];

      setInventories(sortedData);
      setSummary(Object.values(summaryData));
    } catch (error) {
      console.error(t("errors.fetchFailed"), error);
    }
  };

  const fetchAlerts = async () => {
    try {
      const response = await axios.get("/inventory/alerts");
      setAlerts(response.data);
    } catch (error) {
      console.error(t("errors.fetchFailed"), error);
    }
  };

  const getRowClassName = (inventory) => {
    const isQuantityZero = inventory.quantity === 0;
    const isExpired = new Date(inventory.expiration_date) < new Date();
    return isQuantityZero || isExpired ? "table-danger" : "";
  };

  return (
    <div className="container-fluid mt-4 ">
      <h2 className="mb-3">{t("inventoryTracking.title")}</h2>
      {alerts.length > 0 && (
        <div className="alert alert-warning">
          <h4>{t("inventoryTracking.alerts.title")}</h4>
          <ul>
            {alerts.map((alert) => (
              <li key={alert.product_id}>
                {alert.child_category_name} {t("inventoryTracking.in")}{" "}
                {alert.warehouse_name} -
                {alert.quantity < 10
                  ? ` ${t("inventoryTracking.alerts.lowStock")}`
                  : ""}
                {new Date(alert.expiration_date) <=
                new Date(new Date().setDate(new Date().getDate() + 7))
                  ? ` ${t("inventoryTracking.alerts.expiringSoon")}`
                  : ""}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="table-responsive">
        <h3>{t("inventoryTracking.summary")}</h3>
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("inventoryTracking.mainCategory")}</th>
              <th>{t("inventoryTracking.childCategory")}</th>
              <th>{t("inventoryTracking.warehouse")}</th>
              <th>{t("inventoryTracking.totalQuantity")}</th>
            </tr>
          </thead>
          <tbody>
            {summary.length > 0 ? (
              summary.map((item, index) => (
                <tr key={index}>
                  <td>{item.main_category_name}</td>
                  <td>{item.child_category_name}</td>
                  <td>{item.warehouse_name}</td>
                  <td>{item.quantity}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">{t("inventoryTracking.noData")}</td>
              </tr>
            )}
          </tbody>
        </table>

        <h3>{t("inventoryTracking.details")}</h3>
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("inventoryTracking.lotNumber")}</th>
              <th>{t("inventoryTracking.unit")}</th>
              <th>{t("inventoryTracking.childCategory")}</th>
              <th>{t("inventoryTracking.mainCategory")}</th>
              <th>{t("inventoryTracking.warehouse")}</th>
              <th>{t("inventoryTracking.quantity")}</th>
              <th>{t("inventoryTracking.expirationDate")}</th>
            </tr>
          </thead>
          <tbody>
            {inventories.length > 0 ? (
              inventories.map((inventory, index) => (
                <tr key={index} className={getRowClassName(inventory)}>
                  <td>{inventory.lot_number}</td>
                  <td>{inventory.unit}</td>
                  <td>{inventory.child_category_name}</td>
                  <td>{inventory.main_category_name}</td>
                  <td>{inventory.warehouse_name}</td>
                  <td>{inventory.quantity}</td>
                  <td>
                    {new Date(inventory.expiration_date).toLocaleDateString()}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">{t("inventoryTracking.noData")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        <Products />
      </div>
    </div>
  );
}

export default Inventory;
