import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context
export const AuthContext = createContext();

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

// Provider component
export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);

    // Optionally, retrieve the token from local storage when the app loads
    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setToken(storedToken);
        }
    }, []);

    // Function to save token in local storage and update the state
    const saveToken = (newToken) => {
        localStorage.setItem('token', newToken);
        setToken(newToken);
    };

    // Function to clear the token both from local storage and state
    const logout = () => {
        localStorage.removeItem('token');
        setToken(null);
    };

    // The value that will be given to the context
    const value = {
        token,
        setToken: saveToken,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
