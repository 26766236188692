import React, { useState } from "react";
import { Tab, Row, Col } from "react-bootstrap";
import Categories from "./Categories/Categories";
import Units from "./Units/Units";
import VatRates from "./VATRates/VATRates";
import PaymentMethodForm from "./PaymentMethods/PaymentMethods";
import CurrencyForm from "./Currencies/Currencies";
import WarehouseForm from "./Warehouses/Warehouses";
import ExpenseCategories from "./ExpenseCategories/ExpenseCategories";
import { useTranslation } from "react-i18next";
import styles from "./Setup.module.css";

function Setup() {
  const { t } = useTranslation();
  const [key, setKey] = useState("categories");

  const tabs = [
    {
      eventKey: "categories",
      title: t("setup.categories"),
      component: <Categories />,
    },
    {
      eventKey: "expenseCategories",
      title: t("setup.expenseCategories"),
      component: <ExpenseCategories />,
    },
    { eventKey: "units", title: t("setup.units"), component: <Units /> },
    { eventKey: "vat", title: t("setup.vatRates"), component: <VatRates /> },
    {
      eventKey: "paymentMethods",
      title: t("setup.paymentMethods"),
      component: <PaymentMethodForm />,
    },
    {
      eventKey: "currencies",
      title: t("setup.currencies"),
      component: <CurrencyForm />,
    },
    {
      eventKey: "warehouses",
      title: t("setup.warehouses"),
      component: <WarehouseForm />,
    },
  ];

  return (
    <div className="container-fluid mt-4">
      <Row>
        <Col sm={3} className="p-0">
          <div className={styles.tabList}>
            <h2 className="text-center">{t("setup.title")}</h2>

            {tabs.map((tab) => (
              <div
                key={tab.eventKey}
                className={`${styles.customNavLink} ${
                  key === tab.eventKey ? styles.activeTab : ""
                }`}
                onClick={() => setKey(tab.eventKey)}
              >
                <span className="ms-2">{tab.title}</span>
              </div>
            ))}
          </div>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            {tabs.map((tab) => (
              <Tab.Pane
                eventKey={tab.eventKey}
                active={key === tab.eventKey}
                key={tab.eventKey}
              >
                {key === tab.eventKey && tab.component}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </div>
  );
}

export default Setup;
