import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaSave } from "react-icons/fa";
import { ImCross } from "react-icons/im";
function VatRateForm({ isOpen, onClose, onSave, initialData }) {
  const [formData, setFormData] = useState({
    name: "",
    rate: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setFormData({
        name: initialData?.name || "",
        rate: initialData?.rate || "",
        vat_id: initialData?.vat_id || null,
      });
    }
  }, [initialData, isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      vat_id: formData.vat_id,
      name: formData.name,
      rate: formData.rate,
    });
    onClose();
  };

  return (
    <div
      className={`modal modal-xl fade ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {formData.vat_id ? t("vatForm.editTitle") : t("vatForm.addTitle")}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} id="form-100">
              <div className="form-group">
                <label>{t("vatForm.name")}:</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>{t("vatForm.rate")}:</label>
                <input
                  type="number"
                  className="form-control"
                  name="rate"
                  value={formData.rate}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className=" d-flex w-100 justify-content-between mt-3">
                <button type="submit" className="btn btn-success w-25">
                  <FaSave />
                  <span className="text-icon">{t("vatForm.saveButton")}</span>
                </button>
                <button
                  type="button"
                  className="btn btn-danger w-25"
                  onClick={onClose}
                >
                  <ImCross />
                  <span className="text-icon">{t("vatForm.cancelButton")}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VatRateForm;
