import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import AddProductsForm from "./Products-inventory/AddProductsForm";
import axios from "axios";
import { ImCross } from "react-icons/im";

const CutsModal = ({ cuts, setShow, show, selectedProcessStatus }) => {
  console.log("---------------");
  console.log(cuts);
  const [addProductModal, setAddProductModal] = useState(false);
  const [selectedCut, setSelectedCut] = useState({});
  const handleAddProduct = async (productData) => {
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/inventory`;
    try {
      await axios.post(apiEndpoint, productData);
      setShow(false);
    } catch (error) {
      console.error("Failed to add or update product:", error);
    }
  };
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <div
        className={`modal modal-xl fade ${show ? "show" : ""}`}
        style={{ display: show ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setShow(false);
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th>{t("labOutPut.mainCategoryName")}</th>
                      <th>{t("labOutPut.childCategoryName")}</th>
                      <th>{t("labOutPut.lotNumber")}</th>
                      <th>{t("labOutPut.quantity")}</th>
                      <th>{t("labOutPut.percentage")}</th>
                      <th>{t("labProcesses.avaliableAction")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cuts.map((cut, index) => (
                      <tr key={index}>
                        <td>{cut.mainCategoryName}</td>
                        <td>{cut.childCategoryName}</td>
                        <td>{cut.cutLotNumber}</td>
                        <td>{cut.quantity}</td>
                        <td>{cut.percentage} %</td>
                        <td>
                          {" "}
                          {!cut.finished ? (
                            <button
                              type="submit"
                              className="btn btn-success"
                              onClick={() => {
                                setSelectedCut(cut);
                                setAddProductModal(true);
                              }}
                            >
                              <FaSave />
                              <span className="text-icon ms-2">
                                {t("actions.addToInventory")}
                              </span>
                            </button>
                          ) : (
                            <ImCross></ImCross>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {addProductModal && (
          <AddProductsForm
            isOpen={addProductModal}
            onClose={() => setAddProductModal(false)}
            initalData={selectedCut}
            onSave={handleAddProduct}
          />
        )}{" "}
      </div>
    </>
  );
};

export default CutsModal;
