import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import EditCustomerModal from "./EditCustomerModal"; // Ensure this modal is for editing and adding customers
import { FaEdit, FaTrash } from "react-icons/fa";

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [error, setError] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const { t } = useTranslation();

  useEffect(() => {
    fetchCustomers();
    fetchPaymentMethods();
    fetchCurrencies();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/customers`
      );
      setCustomers(response.data);
      setError("");
    } catch (error) {
      console.error("Failed to fetch customers:", error);
      setError(t("errors.fetchFailed"));
    }
  };

  const fetchPaymentMethods = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/setup/payment-methods`
      );
      setPaymentMethods(response.data);
    } catch (error) {
      console.error("Failed to fetch payment methods:", error);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/setup/currencies`
      );
      setCurrencies(response.data);
    } catch (error) {
      console.error("Failed to fetch currencies:", error);
    }
  };

  const handleAddOrUpdateCustomer = async (customerData) => {
    const apiEndpoint = currentCustomer
      ? `${process.env.REACT_APP_API_URL}/customers/${currentCustomer.customer_id}`
      : `${process.env.REACT_APP_API_URL}/customers`;
    try {
      const method = currentCustomer ? "put" : "post";
      await axios[method](apiEndpoint, customerData);
      fetchCustomers();
      closeModal();
    } catch (error) {
      console.error("Failed to add or update customer:", error);
      setError(t("errors.updateFailed"));
    }
  };

  const handleDeleteCustomer = async (customerId) => {
    const confirmDelete = window.confirm(t("confirmDelete"));
    if (!confirmDelete) return;

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/customers/${customerId}`
      );
      fetchCustomers();
    } catch (error) {
      console.error("Failed to delete customer:", error);
      setError(t("errors.deleteFailed"));
    }
  };

  const openModalToAdd = () => {
    setCurrentCustomer(null);
    setModalIsOpen(true);
  };

  const openModalToEdit = (customer) => {
    setCurrentCustomer(customer);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentCustomer(null);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredCustomers = customers.filter((customer) => {
    return (
      customer.name.toLowerCase().includes(searchQuery) ||
      customer.address.toLowerCase().includes(searchQuery) ||
      customer.email.toLowerCase().includes(searchQuery) ||
      customer.vat_number.toLowerCase().includes(searchQuery) ||
      customer.phone.toLowerCase().includes(searchQuery) ||
      customer.bank_account.toLowerCase().includes(searchQuery) ||
      customer.payment_method.toLowerCase().includes(searchQuery) ||
      customer.currency.toLowerCase().includes(searchQuery) ||
      customer.contact_person.toLowerCase().includes(searchQuery) ||
      customer.contact_email.toLowerCase().includes(searchQuery)
    );
  });

  return (
    <div className="container-fluid mt-4">
      <h2>{t("customers.title")}</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="d-flex justify-content-between">
        <button
          className="btn btn-primary my-3 w-29-5 text-center"
          onClick={openModalToAdd}
        >
          {t("customers.addNew")}
        </button>
        <input
          type="text"
          className="form-control mb-3 w-25 h-75"
          placeholder={t("customers.search")}
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("customers.name")}</th>
              <th>{t("customers.address")}</th>
              <th>{t("customers.email")}</th>
              <th>{t("customers.vatNumber")}</th>
              <th>{t("customers.phone")}</th>
              <th>{t("customers.bankAccount")}</th>
              <th>{t("customers.paymentMethod")}</th>
              <th>{t("customers.currency")}</th>
              <th>{t("customers.contactPerson")}</th>
              <th>{t("customers.contactEmail")}</th>
              <th>{t("customers.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map((customer) => (
              <tr key={customer.customer_id}>
                <td>{customer.name}</td>
                <td>{customer.address}</td>
                <td>{customer.email}</td>
                <td>{customer.vat_number}</td>
                <td>{customer.phone}</td>
                <td>{customer.bank_account}</td>
                <td>{customer.payment_method}</td>
                <td>{customer.currency}</td>
                <td>{customer.contact_person}</td>
                <td>{customer.contact_email}</td>
                <td className="d-flex">
                  <button
                    className="btn btn-success m-1 "
                    onClick={() => openModalToEdit(customer)}
                  >
                    <FaEdit />
                    <span className="text-icon"> {t("customers.edit")} </span>
                  </button>
                  <button
                    className="btn btn-danger m-1"
                    onClick={() => handleDeleteCustomer(customer.customer_id)}
                  >
                    <FaTrash />
                    <span className="text-icon"> {t("customers.delete")} </span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalIsOpen && (
        <EditCustomerModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSave={handleAddOrUpdateCustomer}
          initialData={currentCustomer}
          paymentMethods={paymentMethods}
          currencies={currencies}
        />
      )}
    </div>
  );
}

export default Customers;
