import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaSave } from "react-icons/fa";
import { ImCross } from "react-icons/im";

function EditSupplierModal({ isOpen, onClose, onSave, initialData }) {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
    contact_person: "",
    contact_email: "",
    bank_account: "",
    payment_method: "",
    currency: "",
    vat_number: "",
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setFormData({
        name: initialData?.name || "",
        address: initialData?.address || "",
        email: initialData?.email || "",
        phone: initialData?.phone || "",
        contact_person: initialData?.contact_person || "",
        contact_email: initialData?.contact_email || "",
        bank_account: initialData?.bank_account || "",
        payment_method: initialData?.payment_method || "",
        currency: initialData?.currency || "",
        vat_number: initialData?.vat_number || "",
      });
      fetchPaymentMethods();
      fetchCurrencies();
    }
  }, [initialData, isOpen]);

  const fetchPaymentMethods = async () => {
    try {
      const response = await axios.get("/setup/payment-methods");
      setPaymentMethods(response.data);
    } catch (error) {
      console.error("Failed to fetch payment methods:", error);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await axios.get("/setup/currencies");
      setCurrencies(response.data);
    } catch (error) {
      console.error("Failed to fetch currencies:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  return (
    <div
      className={`modal modal-xl fade ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {initialData?.supplier_id
                ? t("editSupplierModal.editTitle")
                : t("editSupplierModal.addTitle")}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} id="form-100">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("editSupplierModal.name")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("editSupplierModal.address")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("editSupplierModal.email")}:</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("editSupplierModal.phone")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("editSupplierModal.contactPerson")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="contact_person"
                        value={formData.contact_person}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("editSupplierModal.contactEmail")}:</label>
                      <input
                        type="email"
                        className="form-control"
                        name="contact_email"
                        value={formData.contact_email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("editSupplierModal.bankAccount")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="bank_account"
                        value={formData.bank_account}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("editSupplierModal.paymentMethod")}:</label>
                      <select
                        name="payment_method"
                        className="form-control"
                        value={formData.payment_method}
                        onChange={handleChange}
                        required
                      >
                        <option value="">
                          {t("editSupplierModal.selectPaymentMethod")}
                        </option>
                        {paymentMethods.map((method) => (
                          <option
                            key={method.payment_method_id}
                            value={method.method_name}
                          >
                            {method.method_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("editSupplierModal.currency")}:</label>
                      <select
                        name="currency"
                        className="form-control"
                        value={formData.currency}
                        onChange={handleChange}
                        required
                      >
                        <option value="">
                          {t("editSupplierModal.selectCurrency")}
                        </option>
                        {currencies.map((currency) => (
                          <option
                            key={currency.currency_id}
                            value={currency.currency_code}
                          >
                            {currency.currency_code}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{t("editSupplierModal.vatNumber")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="vat_number"
                        value={formData.vat_number}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6 d-flex justify-content-start">
                    <button type="submit" className="btn btn-success w-50 me-2">
                      <FaSave />
                      <span className="text-icon ms-2">
                        {t("editSupplierModal.saveButton")}
                      </span>
                    </button>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-danger w-50"
                      onClick={onClose}
                    >
                      <ImCross />
                      <span className="text-icon ms-2">
                        {t("editSupplierModal.cancelButton")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSupplierModal;
