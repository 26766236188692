import React, { useState, useEffect } from "react";
import axios from "axios";
import CurrencyForm from "./CurrencyForm";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash } from "react-icons/fa";

function Currencies() {
  const [currencies, setCurrencies] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState({ currency_code: "" });
  const { t } = useTranslation();

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const fetchCurrencies = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/setup/currencies`
      );
      setCurrencies(response.data);
    } catch (error) {
      console.error(t("currencies.fetchError"), error);
    }
  };

  const fetchConversionRate = async (currencyCode) => {
    try {
      const response = await axios.get(
        `https://v6.exchangerate-api.com/v6/dc63dda6f9cef29a8decae8f/latest/HUF`
      );
      const rates = response.data.conversion_rates;
      return rates[currencyCode] || null;
    } catch (error) {
      console.error(
        `Failed to fetch conversion rate for ${currencyCode}:`,
        error
      );
      return null;
    }
  };

  const handleAddOrUpdateCurrency = async (currencyData) => {
    const conversionRate = await fetchConversionRate(
      currencyData.currency_code
    );
    if (!conversionRate) {
      alert(t("currencies.invalidCurrencyCode"));
      return;
    }

    const method = currencyData.currency_id ? "put" : "post";
    const url = currencyData.currency_id
      ? `${process.env.REACT_APP_API_URL}/setup/currencies/${currencyData.currency_id}`
      : `${process.env.REACT_APP_API_URL}/setup/currencies`;
    try {
      const response = await axios[method](url, {
        ...currencyData,
        to_huf_conversion_rate: conversionRate,
      });
      console.log(
        currencyData.currency_id ? "Currency updated" : "Currency added",
        response.data
      );
      fetchCurrencies();
    } catch (error) {
      console.error(
        "Error updating/adding currency:",
        error.response ? error.response.data : error
      );
      alert(
        `Error updating currency: ${
          error.response
            ? JSON.stringify(error.response.data)
            : "Server not responding"
        }`
      );
    }
    closeModal();
  };

  const handleDeleteCurrency = async (currencyId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/setup/currencies/${currencyId}`
      );
      fetchCurrencies();
    } catch (error) {
      console.error(t("currencies.deleteError"), error);
    }
  };

  const openModalToAdd = () => {
    setCurrentCurrency({ currency_code: "" });
    setModalIsOpen(true);
  };

  const openModalToEdit = (currency) => {
    setCurrentCurrency(currency);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentCurrency({ currency_code: "" });
  };

  return (
    <div className="container-fluid ">
      <h2>{t("currencies.title")}</h2>
      <button
        className="btn btn-primary my-3 w-29-5 text-center"
        onClick={openModalToAdd}
      >
        {t("currencies.addNew")}
      </button>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("currencies.currencyCode")}</th>
              <th>{t("currencies.toHufConversionRate")}</th>
              <th>{t("currencies.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {currencies.map((currency) => (
              <tr key={currency.currency_id}>
                <td>{currency.currency_code}</td>
                <td>{currency.to_huf_conversion_rate}</td>
                <td className="d-flex">
                  <button
                    className="btn btn-success m-1"
                    onClick={() => openModalToEdit(currency)}
                  >
                    <FaEdit />
                    <span className="text-icon">{t("currencies.edit")}</span>
                  </button>
                  <button
                    className="btn btn-danger m-1"
                    onClick={() => handleDeleteCurrency(currency.currency_id)}
                  >
                    <FaTrash />
                    <span className="text-icon">{t("currencies.delete")}</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalIsOpen && (
        <CurrencyForm
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSave={handleAddOrUpdateCurrency}
          initialData={currentCurrency}
        />
      )}
    </div>
  );
}

export default Currencies;
