import React, { useState, useEffect } from "react";
import axios from "axios";
import CategoryForm from "./CategoryForm";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash } from "react-icons/fa";

function Categories() {
  const [categories, setCategories] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/setup/categories`
      );
      const nestedCategories = nestCategories(response.data || []);
      setCategories(nestedCategories);
    } catch (error) {
      console.error(t("categories.fetchError"), error);
    }
  };

  // Utility function to transform flat category data into nested structure
  const nestCategories = (categories) => {
    const categoryMap = {};
    categories.forEach((category) => {
      categoryMap[category.category_id] = { ...category, children: [] };
    });

    const result = [];
    categories.forEach((category) => {
      if (category.parent_id) {
        if (categoryMap[category.parent_id]) {
          // Ensure the parent exists
          categoryMap[category.parent_id].children.push(
            categoryMap[category.category_id]
          );
        }
      } else {
        result.push(categoryMap[category.category_id]);
      }
    });

    return result;
  };

  const handleAddOrUpdateCategory = async (categoryData) => {
    const method = categoryData.category_id ? "put" : "post";
    const url = categoryData.category_id
      ? `/setup/categories/${categoryData.category_id}`
      : "/setup/categories";
    try {
      const response = await axios[method](url, categoryData);
      console.log(
        categoryData.category_id ? "Category updated" : "Category added",
        response.data
      );
      fetchCategories();
    } catch (error) {
      console.error(
        "Error updating/adding category:",
        error.response ? error.response.data : error
      );
      alert(
        `Error updating category: ${
          error.response
            ? JSON.stringify(error.response.data)
            : "Server not responding"
        }`
      );
    }
    closeModal();
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.delete(`/setup/categories/${categoryId}`);
      fetchCategories();
    } catch (error) {
      console.error(t("categories.deleteError"), error);
    }
  };

  const openModalToAdd = () => {
    setCurrentCategory({
      name: "",
      description: "",
      children: [{ name: "", vat_rate_id: "" }],
    });
    setModalIsOpen(true);
  };

  const openModalToEdit = (category) => {
    const { category_id, name, description, children } = category;
    const childData = children.map((child) => ({
      name: child.name,
      vat_rate_id: child.vat_rate_id,
    }));
    setCurrentCategory({ category_id, name, description, children: childData });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentCategory(null);
  };

  return (
    <div className="container-fluid ">
      <h2>{t("categories.title")}</h2>
      <button
        className="btn btn-primary my-3 w-29-5 text-center"
        onClick={openModalToAdd}
      >
        {t("categories.addNew")}
      </button>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("categories.name")}</th>
              <th>{t("categories.description")}</th>
              <th>{t("categories.children")}</th>
              <th>{t("categories.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category.category_id}>
                <td>{category.name}</td>
                <td>{category.description}</td>
                <td>
                  {category.children &&
                    category.children.map((child, index) => (
                      <li key={index}>
                        {child.name} (VAT: {child.vat_rate})
                      </li>
                    ))}
                </td>
                <td className="d-flex ">
                  <button
                    className="btn btn-success m-1"
                    onClick={() => openModalToEdit(category)}
                  >
                    <FaEdit /> {t("categories.edit")}
                  </button>
                  <button
                    className="btn btn-danger m-1"
                    onClick={() => handleDeleteCategory(category.category_id)}
                  >
                    <FaTrash /> {t("categories.delete")}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalIsOpen && (
        <CategoryForm
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSave={handleAddOrUpdateCategory}
          initialData={
            currentCategory || {
              name: "",
              description: "",
              children: [{ name: "", vat_rate_id: "" }],
            }
          }
        />
      )}
    </div>
  );
}

export default Categories;
