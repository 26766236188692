import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

function Expenses() {
  const { t } = useTranslation();
  const [expenses, setExpenses] = useState([]);
  const [newExpense, setNewExpense] = useState({
    date: new Date(),
    amount: "",
    category_id: "",
    vat_rate_id: "",
    description: "",
  });
  const [editingExpense, setEditingExpense] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const [vatRates, setVatRates] = useState([]);

  useEffect(() => {
    fetchExpenses();
    fetchCategories();
    fetchVatRates();
  }, []);

  const fetchExpenses = async () => {
    try {
      const response = await axios.get("/expenses");
      setExpenses(response.data);
    } catch (error) {
      console.error(t("errors.fetchFailed"), error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get("/setup/expense-categories");
      setCategories(response.data);
    } catch (error) {
      console.error(t("errors.fetchFailed"), error);
    }
  };

  const fetchVatRates = async () => {
    try {
      const response = await axios.get("/setup/vat-rates");
      setVatRates(response.data);
    } catch (error) {
      console.error(t("errors.fetchFailed"), error);
    }
  };

  const addExpense = async () => {
    if (
      !newExpense.date ||
      !newExpense.amount ||
      !newExpense.category_id ||
      !newExpense.vat_rate_id
    ) {
      setErrorMessage(t("expenses.errors.requiredFields"));
      return;
    }

    try {
      await axios.post("/expenses", newExpense);
      fetchExpenses();
      setNewExpense({
        date: new Date(),
        amount: "",
        category_id: "",
        vat_rate_id: "",
        description: "",
      });
      setErrorMessage("");
    } catch (error) {
      console.error(t("errors.addFailed"), error);
    }
  };

  const updateExpense = async (id) => {
    try {
      await axios.put(`/expenses/${id}`, editingExpense);
      fetchExpenses();
      setEditingExpense(null);
      setErrorMessage("");
    } catch (error) {
      console.error(t("errors.updateFailed"), error);
    }
  };

  const deleteExpense = async (id) => {
    try {
      await axios.delete(`/expenses/${id}`);
      fetchExpenses();
    } catch (error) {
      console.error(t("errors.deleteFailed"), error);
    }
  };

  const cancelEditing = () => {
    setEditingExpense(null);
    setErrorMessage("");
  };

  return (
    <div className="container mt-4">
      <h2>{t("expenses.title")}</h2>
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      <div className="mb-3">
        <DatePicker
          selected={newExpense.date}
          onChange={(date) => setNewExpense({ ...newExpense, date })}
          className="form-control mb-2"
          dateFormat="yyyy-MM-dd"
        />
        <div className="input-group mb-2">
          <input
            type="number"
            className="form-control"
            placeholder={t("expenses.amount")}
            value={newExpense.amount}
            onChange={(e) =>
              setNewExpense({ ...newExpense, amount: e.target.value })
            }
          />
          <div className="input-group-append">
            <span className="input-group-text">Ft</span>
          </div>
        </div>
        <select
          className="form-control mb-2"
          value={newExpense.category_id}
          onChange={(e) =>
            setNewExpense({ ...newExpense, category_id: e.target.value })
          }
        >
          <option value="">{t("expenses.selectCategory")}</option>
          {categories.map((category) => (
            <option
              key={category.expense_category_id}
              value={category.expense_category_id}
            >
              {category.main_category} - {category.sub_category}
            </option>
          ))}
        </select>
        <select
          className="form-control mb-2"
          value={newExpense.vat_rate_id}
          onChange={(e) =>
            setNewExpense({ ...newExpense, vat_rate_id: e.target.value })
          }
        >
          <option value="">{t("expenses.selectVatRate")}</option>
          {vatRates.map((vatRate) => (
            <option key={vatRate.vat_id} value={vatRate.vat_id}>
              {vatRate.name} - {vatRate.rate}%
            </option>
          ))}
        </select>
        <textarea
          className="form-control mb-2"
          placeholder={t("expenses.description")}
          value={newExpense.description}
          onChange={(e) =>
            setNewExpense({ ...newExpense, description: e.target.value })
          }
        />
        <button className="btn btn-primary" onClick={addExpense}>
          {t("expenses.add")}
        </button>
      </div>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th>{t("expenses.date")}</th>
            <th>{t("expenses.amount")}</th>
            <th>{t("expenses.category")}</th>
            <th>{t("expenses.vatRate")}</th>
            <th>{t("expenses.description")}</th>
            <th>{t("actions.edit")}</th>
            <th>{t("actions.delete")}</th>
          </tr>
        </thead>
        <tbody>
          {expenses.map((expense) => (
            <tr key={expense.expense_id}>
              <td>{new Date(expense.date).toLocaleDateString()}</td>
              <td>{`${parseFloat(expense.amount).toFixed(2)} Ft`}</td>
              <td>{`${expense.main_category} - ${expense.sub_category}`}</td>
              <td>{`${expense.vat_rate}%`}</td>
              <td>{expense.description}</td>
              <td>
                <button
                  className="btn btn-info"
                  onClick={() => setEditingExpense(expense)}
                >
                  {t("actions.edit")}
                </button>
              </td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteExpense(expense.expense_id)}
                >
                  {t("actions.delete")}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editingExpense && (
        <div>
          <DatePicker
            selected={new Date(editingExpense.date)}
            onChange={(date) => setEditingExpense({ ...editingExpense, date })}
            className="form-control mb-2"
            dateFormat="yyyy-MM-dd"
          />
          <div className="input-group mb-2">
            <input
              type="number"
              className="form-control"
              value={editingExpense.amount}
              onChange={(e) =>
                setEditingExpense({
                  ...editingExpense,
                  amount: e.target.value,
                })
              }
            />
            <div className="input-group-append">
              <span className="input-group-text">Ft</span>
            </div>
          </div>
          <select
            className="form-control mb-2"
            value={editingExpense.category_id}
            onChange={(e) =>
              setEditingExpense({
                ...editingExpense,
                category_id: e.target.value,
              })
            }
          >
            <option value="">{t("expenses.selectCategory")}</option>
            {categories.map((category) => (
              <option
                key={category.expense_category_id}
                value={category.expense_category_id}
              >
                {category.main_category} - {category.sub_category}
              </option>
            ))}
          </select>
          <select
            className="form-control mb-2"
            value={editingExpense.vat_rate_id}
            onChange={(e) =>
              setEditingExpense({
                ...editingExpense,
                vat_rate_id: e.target.value,
              })
            }
          >
            <option value="">{t("expenses.selectVatRate")}</option>
            {vatRates.map((vatRate) => (
              <option key={vatRate.vat_id} value={vatRate.vat_id}>
                {vatRate.name} - {vatRate.rate}%
              </option>
            ))}
          </select>
          <textarea
            className="form-control mb-2"
            value={editingExpense.description}
            onChange={(e) =>
              setEditingExpense({
                ...editingExpense,
                description: e.target.value,
              })
            }
          />
          <button
            className="btn btn-primary"
            onClick={() => updateExpense(editingExpense.expense_id)}
          >
            {t("expenses.update")}
          </button>
          <button className="btn btn-secondary" onClick={cancelEditing}>
            {t("actions.cancel")}
          </button>
        </div>
      )}
    </div>
  );
}

export default Expenses;
