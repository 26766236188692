import React, { useState, useEffect } from "react";
import axios from "axios";
import WarehouseForm from "./WarehouseForm";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash } from "react-icons/fa";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

function Warehouses() {
  const [warehouses, setWarehouses] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentWarehouse, setCurrentWarehouse] = useState({
    name: "",
    location: "",
    capacity: "",
    manager: "",
    contact_details: "",
    status: "Active",
    temperature: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    fetchWarehouses();
  }, []);

  const fetchWarehouses = async () => {
    try {
      const response = await axios.get("/setup/warehouses");
      setWarehouses(response.data);
    } catch (error) {
      console.error(t("warehouses.fetchError"), error);
    }
  };

  const handleAddOrUpdateWarehouse = async (warehouseData) => {
    const method = warehouseData.warehouse_id ? "put" : "post";
    const url = warehouseData.warehouse_id
      ? `/setup/warehouses/${warehouseData.warehouse_id}`
      : "/setup/warehouses";
    try {
      const response = await axios[method](url, warehouseData);
      console.log(
        warehouseData.warehouse_id ? "Warehouse updated" : "Warehouse added",
        response.data
      );
      fetchWarehouses();
    } catch (error) {
      console.error(
        "Error updating/adding warehouse:",
        error.response ? error.response.data : error
      );
      alert(
        `Error updating warehouse: ${
          error.response
            ? JSON.stringify(error.response.data)
            : "Server not responding"
        }`
      );
    }
    closeModal();
  };

  const handleDeleteWarehouse = async (warehouseId) => {
    try {
      await axios.delete(`/setup/warehouses/${warehouseId}`);
      fetchWarehouses();
    } catch (error) {
      console.error(t("warehouses.deleteError"), error);
    }
  };

  const openModalToAdd = () => {
    setCurrentWarehouse({
      name: "",
      location: "",
      capacity: "",
      manager: "",
      contact_details: "",
      status: "Active",
      temperature: "",
    });
    setModalIsOpen(true);
  };

  const openModalToEdit = (warehouse) => {
    setCurrentWarehouse(warehouse);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentWarehouse({
      name: "",
      location: "",
      capacity: "",
      manager: "",
      contact_details: "",
      status: "Active",
      temperature: "",
    });
  };

  return (
    <div className="container-fluid ">
      <h2>{t("warehouses.title")}</h2>
      <button
        c
        className="btn btn-primary my-3 w-29-5 text-center"
        onClick={openModalToAdd}
      >
        {t("warehouses.addNew")}
      </button>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>{t("warehouses.name")}</th>
              <th>{t("warehouses.location")}</th>
              <th>{t("warehouses.capacity")}</th>
              <th>{t("warehouses.manager")}</th>
              <th>{t("warehouses.contactDetails")}</th>
              <th>{t("warehouses.status")}</th>
              <th>{t("warehouses.temperature")}</th>
              <th>{t("warehouses.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {warehouses.map((warehouse) => (
              <tr key={warehouse.warehouse_id}>
                <td>{warehouse.name}</td>
                <td>{warehouse.location}</td>
                <td>{warehouse.capacity}</td>
                <td>{warehouse.manager}</td>
                <td>{warehouse.contact_details}</td>
                <td>{warehouse.status}</td>
                <td>{warehouse.temperature}</td>
                <td className="d-flex ">
                  <button
                    className="btn btn-success m-1"
                    onClick={() => openModalToEdit(warehouse)}
                  >
                    <FaEdit />

                    <span className="text-icon"> {t("warehouses.edit")} </span>
                  </button>
                  <button
                    className="btn btn-danger m-1"
                    onClick={() =>
                      handleDeleteWarehouse(warehouse.warehouse_id)
                    }
                  >
                    <FaTrash />

                    <span className="text-icon"> {t("warehouses.delete")}</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalIsOpen && (
        <WarehouseForm
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSave={handleAddOrUpdateWarehouse}
          initialData={currentWarehouse}
        />
      )}
    </div>
  );
}

export default Warehouses;
